import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";

export class Numbers {

  public static MaxJavaScriptSafeInteger: number = 9007199254740991;
  public static MinJavaScriptSafeInteger: number = -9007199254740991;
  public static MaxInt32: number = 2147483647;
  public static MinInt32: number = -2147483647;
  public static MaxInt16: number = 32767;
  public static MinInt16: number = -32767;

}


export class DateFormat {
  public static FnsIsoDateTime: string = "yyyy-MM-dd'T'HH:mm:ss";
  public static Moment24HHMMSS: string = "HH:mm:ss";
  public static Moment24HHMM: string = "HH:mm";
}

/**
Constants for local storage items.
*/
export class LocalStorage {
  public static UserObject: string = "user";
  public static UserObjectPending: string = "UserPending";
  public static LoginErrorMessage: string = "LoginErrorMessage";
  public static ApiUrl: string = "ApiUrl";
  public static AuthenticationToken: string = "token";
  public static PartnerExternalAuthenticationToken: string = "peat";
  public static ApiKey: string = "ApiKey";
  public static DeviceId: string = "DeviceId";
  public static LastUserNoticeAt: string = "LastUserNoticeAt";
  public static CustomerListDefaults: string = "CustomerListDefaults";
  public static VendorListDefaults: string = "VendorListDefaults";
  public static WarehouseListDefaults: string = "WarehouseListDefaults";
  public static DirectoryListDefaults: string = "DirectoryListDefaults";
  public static InventoryTypeListDefaults: string = "InventoryTypeListDefaults";
  public static InventoryListDefaults: string = "InventoryListDefaults";
  public static AssetListDefaults: string = "AssetListDefaults";
  public static AttachmentListDefaults: string = "AttachmentListDefaults";
  public static CaseListDefaults: string = "CaseListDefaults";
  public static CaseTemplateListDefaults: string = "CaseTemplateListDefaults";
  public static TaskListTemplateListDefaults: string = "TaskListTemplateListDefaults";
  public static TaskTemplateListDefaults: string = "TaskTemplateListDefaults";
  public static TaskListListDefaults: string = "TaskListListDefaults";
  public static TaskListDefaults: string = "TaskListDefaults";
  public static NotificationEventListDefaults: string = "NotificationEventListDefaults";
  public static NotificationGroupListDefaults: string = "NotificationGroupListDefaults";
  public static NotificationContactListDefaults: string = "NotificationContactListDefaults";
  public static ReportCompilerFileListDefaults: string = "ReportCompilerFileListDefaults";
  public static ReportCompilerFileTemplateListDefaults: string = "ReportCompilerFileTemplateListDefaults";
  public static ReportCompilerLibraryListDefaults: string = "ReportCompilerLibraryListDefaults";
  public static PickListCategoryListDefaults: string = "PickListCategoryListDefaults";
  public static AttributeSetListDefaults: string = "AttributeSetListDefaults";
  public static FormListDefaults: string = "FormListDefaults";
  public static PaymentProviderListDefaults: string = "PaymentProviderListDefaults";
  public static PaymentMethodCardTypeListDefaults: string = "PaymentMethodCardTypeListDefaults";
  public static FilterListDefaults: string = "FilterListDefaults";
  public static QueryListDefaults: string = "QueryListDefaults";
  public static CurrencyListDefaults: string = "CurrencyListDefaults";
  public static TimeZoneListDefaults: string = "TimeZoneListDefaults";
  public static NumberingPlanListDefaults: string = "NumberingPlanListDefaults";
  public static BillingTransactionListDefaults: string = "BillingTransactionListDefaults";
  public static RoleListDefaults: string = "RoleListDefaults";
  public static None: string = "";
}

/**
Constants for session storage items.
*/
export class SessionStorage {
  public static AuthenticationToken: string = "token";
  public static ApiKey: string = "ApiKey";
  public static None: string = "";
}

/**
Constants for data model names used for accessing the documentation API.  The format is the table name or if there is alternate documentation
it is a data model name with leading underscore and alternate suffix of a hyphen and table name that is the basis of that alternate
documentation.
@example
// Data model documentation matches the data dictionary documentation for the table underlying table.
"TimeZone"
@example
// Data model is completely customized and has no association with an underlying table.
"_ApplicationInformation"
@example
// Data model is customized but that customization is based on the documentation for an underlying table.
"_PaymentMethodCreditCard-PaymentMethod"
*/
export class DataModelName {

  public static Customer: string = "_CustomerEditViewModel~CustomerEditViewModel";
  public static CustomerList: string = "_CustomerListViewModel~CustomerListViewModel";
  public static Directory: string = "_Directory-CONTACT";
  public static DirectoryList: string = "_DirectoryListViewModel~DirectoryListViewModel";
  public static Group: string = "_GroupEditViewModel~GroupEditViewModel";
  public static GroupList: string = "_GroupListViewModel~GroupListViewModel";
  public static GroupMembership: string = "_ContactGroupMembershipEditViewModel~ContactGroupMembershipEditViewModel";
  public static Location: string = "_LocationEditViewModel~LocationEditViewModel";
  public static LocationList: string = "_LocationListViewModel~LocationListViewModel";
  public static Vendor: string = "_Vendor-CONTACT";
  public static VendorList: string = "_VendorListViewModel~VendorListViewModel";
  public static Warehouse: string = "_Warehouse-CONTACT";
  public static WarehouseList: string = "_WarehouseListViewModel~WarehouseListViewModel";
  public static Contact: string = "_Contact-CONTACT";
  public static ContactList: string = "_ContactListViewModel~ContactListViewModel";
  public static ContactBulkAddRequest: string = "_ContactBulkAddRequestViewModel~ContactBulkAddRequestViewModel";
  public static ContactBulkAddResult: string = "_ContactBulkAddResultViewModel~ContactBulkAddResultViewModel";
  public static ContactRole: string = "ContactRole";
  public static ContactWorkSchedule: string = "ContactWorkSchedule";
  public static ContactWorkScheduleException: string = "ContactWorkScheduleException";
  public static ContactExternalAuthentication: string = "ContactExternalAuthentication";
  public static SalesOpportunity: string = "SalesOpportunity";

  public static SecurityAuthenticate: string = "_AuthenticatedUserViewModel~AuthenticatedUserViewModel";
  public static SecurityLogin: string = "_LoginRequest~LoginRequest";
  public static SecurityLoginNameAvailable: string = "_LoginNameAvailableRequestViewModel~LoginNameAvailableRequestViewModel";
  public static SecurityLoginRecover: string = "_LoginRecoveryRequestViewModel~LoginRecoveryRequestViewModel";
  public static SecurityLoginChange: string = "_LoginChangeRequest~LoginChangeRequest";
  public static SecurityPasswordReset: string = "_PasswordResetLinkRequestViewModel~PasswordResetLinkRequestViewModel";
  public static SecurityPasswordChange: string = "_PasswordChangeRequest~PasswordChangeRequest";
  public static SecurityPasswordView: string = "_PasswordViewRequest~PasswordViewRequest";
  public static SecurityMultiFactorAuthenticationOptions: string = "_MultiFactorAuthenticationOptionsViewModel~MultiFactorAuthenticationOptionsViewModel";
  public static SecurityMultiFactorAuthentication: string = "_MultiFactorAuthenticationRequestViewModel~MultiFactorAuthenticationRequestViewModel";
  public static SecurityPolicy: string = "_SecurityPolicyViewModel~SecurityPolicyViewModel";

  public static InventoryType: string = "InventoryType";
  public static InventoryTypeList: string = "_InventoryTypeListViewModel~InventoryTypeListViewModel";
  public static InventoryTypeVersion: string = "InventoryTypeVersion";
  public static InventoryTypeVersionReleaseNoteCreateRequestModel: string = "_InventoryTypeVersionReleaseNoteCreateRequestModel~InventoryTypeVersionReleaseNoteCreateRequestModel";
  public static InventoryTypeVersionBatchAddModel: string = "_InventoryTypeVersionBatchAddModel~InventoryTypeVersionBatchAddModel";
  public static InventoryQuantityType: string = "InventoryQuantityType";
  public static Inventory: string = "Inventory";
  public static InventoryHistory: string = "InventoryHistory";
  public static InventoryLocation: string = "InventoryLocation";
  public static InventoryEvent: string = "InventoryEvent";
  public static InventoryList: string = "_InventoryListViewModel~InventoryListViewModel";
  public static InventoryExpiringItem: string = "_InventoryExpiringItem~InventoryExpiringViewModel";

  public static CachePackageSignup: string = "_CachePackageSignupEditViewModel~CachePackageSignupEditViewModel";

  public static Association: string = "_AssociationEditViewModel~AssociationEditViewModel";
  public static AssociationList: string = "_AssociationListViewModel~AssociationListViewModel";
  public static AuthorizationCode: string = "_AuthorizationCodeEditViewModel~AuthorizationCodeEditViewModel";
  public static AuthorizationCodeList: string = "_AuthorizationCodeListViewModel~AuthorizationCodeListViewModel";

  public static Activity: string = "_ActivityEditViewModel~ActivityEditViewModel";

  public static VoucherBatch: string = "VoucherBatch";
  public static VoucherBatchVisibility: string = "_VoucherBatchVisibilityEditViewModel~VoucherBatchVisibilityEditViewModel";
  public static VoucherBatchRestrictionRules: string = "_VoucherBatchRestrictionRuleEditViewModel~VoucherBatchRestrictionRuleEditViewModel";
  public static VoucherLot: string = "VoucherLot";
  public static Voucher: string = "Voucher";
  public static VoucherCheck: string = "_VoucherCheckResponseModel~VoucherCheckResponseModel";
  public static VoucherAddFromListAddViewModel: string = "_VoucherAddFromListAddViewModel~VoucherAddFromListAddViewModel";

  public static PaymentProvider: string = "_PaymentProviderEditViewModel~PaymentProviderEditViewModel";
  public static PaymentProviderSupportedCardType: string = "PaymentProviderSupportedCardType";
  public static PaymentProviderSelectionRule: string = "PaymentProviderSelectionRule";
  public static PaymentProviderTrigger: string = "PaymentProviderTrigger";
  public static PaymentMethodCardType: string = "PaymentMethodCardType";
  public static PaymentMethodCreditCard: string = "_PaymentMethodCreditCardEditViewModel~PaymentMethodCreditCardEditViewModel";
  public static PaymentMethod: string = "_PaymentMethodEditViewModel~PaymentMethodEditViewModel";
  public static PaymentMethodList: string = "_PaymentMethodListViewModel~PaymentMethodListViewModel";
  public static PaymentTransaction: string = "_PaymentTransaction-PaymentTransactionYYYYMM";
  public static PaymentTransactionAdd: string = "_PaymentTransactionAdd-PaymentTransactionYYYYMM";
  public static PaymentTransactionSale: string = "_PaymentTransactionSaleAddViewModel~PaymentTransactionSaleAddViewModel";
  public static PaymentTransactionRefund: string = "_PaymentTransactionRefundAddViewModel~PaymentTransactionRefundAddViewModel";
  public static PaymentTransactionEdit: string = "_PaymentTransactionEdit-PaymentTransactionYYYYMM";
  public static PaymentNotification: string = "_PaymentNotificationAddViewModel~PaymentNotificationAddViewModel";

  public static FileServer: string = "FileServer";

  public static JobType: string = "_JobType";
  public static Job: string = "_Job-Job";
  public static JobDirectorySync: string = "_JobDirectorySync-Job";
  public static JobNotificationEngine: string = "_JobNotificationEngine-Job";
  public static JobAlarmRule: string = "_JobAlarmRule-Job";

  public static SyncDataStore: string = "SyncDataStore";
  public static SyncPublisherArticleType: string = "SyncPublisherArticleType";
  public static SyncSubscription: string = "SyncSubscription";
  public static SyncArticle: string = "_SyncArticle-SyncArticleYYYYMM";

  public static NotificationContact: string = "NotificationContact";
  public static NotificationMessage: string = "NotificationMessage";
  public static NotificationOptOut: string = "NotificationOptOut";
  public static NotificationGroup: string = "NotificationGroup";
  public static NotificationGroupDetail: string = "NotificationGroupDetail";
  public static NotificationEvent: string = "_NotificationEvent-NotificationEventYYYYMM";
  public static Notification: string = "_Notification-NotificationYYYYMM";
  public static ActionLink: string = "_ActionLinkEditViewModel~ActionLinkEditViewModel";
  public static AlarmRule: string = "AlarmRule";
  public static AlarmRuleNotification: string = "AlarmRuleNotification";
  public static ServerResourceAlarmRule: string = "_ServerResourceAlarmRuleViewModel~ServerResourceAlarmRuleViewModel";

  public static ManagementMerge: string = "_ManagementMerge";
  public static ManagementNotDuplicate: string = "_ManagementNotDuplicate";
  public static ManagementPossibleDuplicate: string = "_ManagementPossibleDuplicate";
  public static ManagementPossibleDuplicateChildSynopsis: string = "_ManagementPossibleDuplicateChildSynopsis";

  public static PayPalAuthorizationRequest: string = "_PayPalAuthorizationRequestModel~PayPalAuthorizationRequestModel";
  public static PayPalAuthorizationResponse: string = "_PayPalAuthorizationResponseModel~PayPalAuthorizationResponseModel";

  public static BillingProfileList: string = "_BillingProfileListViewModel~BillingProfileListViewModel";
  public static BillingProfile: string = "BillingProfile";
  public static BillingReportProfileList: string = "_BillingReportProfileListViewModel~BillingReportProfileListViewModel";
  public static BillingReportProfile: string = "BillingReportProfile";
  public static BillingReport: string = "BillingReport";
  public static BillingAccountList: string = "_BillingAccountListViewModel~BillingAccountListViewModel";
  public static BillingAccount: string = "BillingAccount";
  public static BillingTransactionList: string = "_BillingTransactionListViewModel~BillingTransactionListViewModel";
  public static BillingTransaction: string = "BillingTransaction";
  public static BillingTransactionDetail: string = "BillingTransactionDetail";
  public static BillingEvent: string = "BillingEvent";
  public static PackageList: string = "_PackageListViewModel~PackageListViewModel";
  public static Package: string = "Package";
  public static PackageItem: string = "PackageItem";
  public static PackageRateAdjustment: string = "PackageRateAdjustment";
  public static PackageAssociation: string = "PackageAssociation";
  public static PackageInventory: string = "PackageInventory";
  public static Item: string = "Item";
  public static ItemRate: string = "ItemRate";
  public static ItemList: string = "ItemList";
  public static PackageOccurrenceList: string = "_PackageOccurrenceListViewModel~PackageOccurrenceListViewModel";
  public static PackageOccurrence: string = "PackageOccurrence";
  public static PackageOccurrenceItem: string = "PackageOccurrenceItem";
  public static PackageOccurrenceRateAdjustment: string = "PackageOccurrenceRateAdjustment";
  public static PurchaseRequest: string = "_Purchase~PurchaseRequestModel";
  public static PurchaseResponse: string = "_Purchase~PurchaseResponseModel";

  public static TaxConfiguration: string = "TaxConfiguration";
  public static TaxGeocode: string = "TaxGeocode";
  public static TaxGeocodeLookup: string = "TaxGeocodeLookup";
  public static TaxJurisdiction: string = "TaxJurisdiction";
  public static TaxMatrix: string = "TaxMatrix";
  public static TaxTransactionDetail: string = "TaxTransactionDetail";
  public static TaxCalculation: string = "_TaxCalculationViewModel~TaxCalculationViewModel";


  public static Case: string = "Cases";
  public static CaseList: string = "_CaseListViewModel~CaseListViewModel";
  public static CaseNote: string = "_CaseNote-Note";
  public static CaseTag: string = "_CaseTag-Tag";
  public static CaseFeedback: string = "CaseFeedback";
  public static CaseCorrespondence: string = "CaseCorrespondence";
  public static CaseCorrespondenceRecipient: string = "CaseCorrespondenceRecipient";
  public static CaseAttachment: string = "_CaseAttachment-Asset";
  public static CaseAccess: string = "CaseAccess";
  public static CaseCloseRequestViewModel: string = "_CaseCloseRequestViewModel~CaseCloseRequestViewModel";
  public static CaseTemplate: string = "CaseTemplate";
  public static CaseTemplateList: string = "_CaseTemplateListViewModel~CaseTemplateListViewModel";
  public static TaskListStatus: string = "_TaskListStatusViewModel~TaskListStatusViewModel";

  public static TaskListTemplate: string = "TaskListTemplate";
  public static TaskTemplate: string = "TaskTemplate";
  public static TaskSubtaskTemplate: string = "TaskSubtaskTemplate";
  public static TaskList: string = "TaskList";
  public static Task: string = "Task";
  public static TaskSubtask: string = "TaskSubtask";

  public static ProcessTemplate: string = "ProcessTemplate";
  public static ProcessTemplateStep: string = "ProcessTemplateStep";
  public static Process: string = "Process";
  public static ProcessStep: string = "ProcessStep";
  public static ProcessStepList: string = "_ProcessStepListViewModel~ProcessStepListViewModel";

  public static ReportCompilerLibraryGroupConfiguration: string = "_ReportCompilerLibraryGroupConfigurationModel~ReportCompilerLibraryGroupConfigurationModel";
  public static ReportCompilerTemplateTypeConfiguration: string = "_ReportCompilerTemplateTypeConfigurationModel~ReportCompilerTemplateTypeConfigurationModel";
  public static ReportCompilerIndustryLibraryTypeInformation: string = "_ReportCompilerIndustryLibraryTypeInformationModel~ReportCompilerIndustryLibraryTypeInformationModel";
  public static ReportCompilerIndustryLibraryApproval: string = "_ReportCompilerIndustryLibraryApprovalViewModel~ReportCompilerIndustryLibraryApprovalViewModel";
  public static ReportCompilerCaseOptions: string = "_ReportCompilerCaseOptionsModel~ReportCompilerCaseOptionsModel";
  public static ReportCompilerReportSection: string = "_ReportCompilerReportSectionConfigurationModel~ReportCompilerReportSectionConfigurationModel";
  public static ReportCompilerReportSectionText: string = "_ReportCompilerReportSectionTextModel~ReportCompilerReportSectionTextModel";
  public static ReportCompilerReportAttachment: string = "_ReportCompilerReportAttachmentConfigurationModel~ReportCompilerReportAttachmentConfigurationModel";
  public static ReportCompilerReportAttachmentDocument: string = "_ReportCompilerReportAttachmentDocumentModel~ReportCompilerReportAttachmentDocumentModel";
  public static ReportCompilerSubjectMatterOther: string = "_ReportCompilerSubjectMatterOtherModel~ReportCompilerSubjectMatterOtherModel";
  public static ReportCompilerSubjectMatterStructural: string = "_ReportCompilerSubjectMatterStructuralPropertyModel~ReportCompilerSubjectMatterStructuralPropertyModel";

  public static ReportCompilerReportParserResultModel: string = "_ReportCompilerReportParserResultModel~ReportCompilerReportParserResultModel";
  public static ReportCompilerReportParserResultAssetModel: string = "_ReportCompilerReportParserResultAssetModel~ReportCompilerReportParserResultAssetModel";
  public static ReportCompilerReportParserParagraphMetaDataEditViewModel: string = "_ReportCompilerReportParserParagraphMetaDataEditViewModel~ReportCompilerReportParserParagraphMetaDataEditViewModel";

  // public static ReportCompilerFile: string = "_rcFileEditViewModel~rcFileEditViewModel";
  // public static ReportCompilerFileList: string = "_rcFileListViewModel~rcFileListViewModel";
  // public static ReportCompilerFileTaskList: string = "_ReportCompilerFileTaskList-CaseTaskList";
  // public static ReportCompilerFileTask: string = "_ReportCompilerFileTask-CaseTask";
  // public static ReportCompilerFileNote: string = "_ReportCompilerFileNote-Note";
  // public static ReportCompilerFileAttachment: string = "_ReportCompilerFileAttachment-Asset";
  // public static ReportCompilerFileSuggestion: string = "_rcAssetTextSelectionListViewModel~rcAssetTextSelectionListViewModel";
  // public static ReportCompilerFileTemplate: string = "_ReportCompilerFileTemplate-CaseTemplate";
  // public static ReportCompilerFileTemplateTaskList: string = "_ReportCompilerFileTemplateTaskList-CaseTemplateTaskList";
  // public static ReportCompilerFileTemplateTask: string = "_ReportCompilerFileTemplateTask-CaseTemplateTask";
  public static ReportCompilerLibraryStats: string = "_ReportCompilerLibraryStatsModel~ReportCompilerLibraryStatsModel";
  public static ReportCompilerLibraryCoverage: string = "_ReportCompilerLibraryCoverageSummaryModel~ReportCompilerLibraryCoverageSummaryModel";
  public static PickListImportResult: string = "_PickListImportResult~PickListImportResult";

  public static Asset: string = "Asset";
  public static AssetList: string = "_AssetListViewModel~AssetListViewModel";
  public static AssetFile: string = "_AssetFile";
  public static AssetTag: string = "AssetTag";
  public static AssetSelection: string = "AssetSelection";
  public static AssetReference: string = "AssetReference";
  public static AssetVisibility: string = "_AssetVisibility-Visibility";
  public static AssetAccessLog: string = "AssetAccessLog";
  public static AssetFeedback: string = "AssetFeedback";
  public static AssetLicense: string = "_AssetLicenseViewModel~AssetLicenseViewModel";
  public static AssetSeeAlso: string = "_AssetOptionsSeeAlsoResultViewModel~AssetOptionsSeeAlsoResultViewModel";

  public static Translation: string = "Translation";
  public static TranslationRequest: string = "_TranslationRequestViewModel~TranslationRequestViewModel";
  public static TranslationResponse: string = "_TranslationResponseViewModel~TranslationResponseViewModel";
  public static TranslationAddMissing: string = "_TranslationAddMissingViewModel~TranslationAddMissingViewModel";


  public static Setting: string = "Setting";
  public static Currency: string = "Currency";
  public static CurrencyList: string = "_CurrencyListViewModel~CurrencyListViewModel";
  public static TimeZone: string = "TimeZone";
  public static TimeZoneList: string = "_TimeZoneListViewModel~TimeZoneListViewModel";
  public static DataTableSupport: string = "_DataTableSupportViewModel~DataTableSupportViewModel";
  public static DataImportDefinition: string = "_DataImportDefinitionViewModel~DataImportDefinitionViewModel";
  public static MailServer: string = "_MailServerViewModel~MailServerViewModel";
  public static MailAddressAction: string = "_MailAddressActionViewModel~MailAddressActionViewModel";
  public static SearchConfiguration: string = "_SearchConfigurationViewModel~SearchConfigurationViewModel";
  public static TableConfiguration: string = "_TableConfigurationViewModel~TableConfigurationViewModel";

  public static TelecomNumberingPlan: string = "TelecomNumberingPlan";
  public static TelecomLocationProfile: string = "TelecomLocationProfile";
  public static TelecomLocationStandard: string = "TelecomLocationStandard";
  public static TelecomLocationCustom: string = "TelecomLocationCustom";
  public static TelecomLocationGroupList: string = "TelecomLocationGroupList";
  public static TelecomLocationGroupLink: string = "TelecomLocationGroupLink";

  public static UsageDataSource: string = "UsageDataSource";
  public static UsageImportLog: string = "UsageImportLog";
  public static UsageDataFeed: string = "UsageDataFeed";
  public static UsageServiceIdentification: string = "UsageServiceIdentification";
  public static UsageCostCenterRating: string = "UsageCostCenterRating";

  public static NetworkElement: string = "NetworkElement";
  public static NetworkPortGroup: string = "NetworkPortGroup";
  public static NetworkPort: string = "NetworkPort";

  public static RatingProfile: string = "RatingProfile";
  public static RatingGroup: string = "RatingGroup";
  public static RatingStep: string = "RatingStep";
  public static RatingRate: string = "RatingRate";
  public static RatingSurchargeDiscount: string = "RatingSurchargeDiscount";
  public static RatingZone: string = "RatingZone";
  public static RatingHoliday: string = "RatingHoliday";

  public static Filter: string = "Filter";
  public static Visibility: string = "Visibility";
  public static Alias: string = "Alias";
  public static Reference: string = "Reference";
  public static ObjectStatus: string = "ObjectStatus";
  public static Review: string = "_ReviewViewModel~ReviewViewModel";
  public static Note: string = "Note";
  public static Role: string = "Role";
  public static RoleList: string = "_RoleListViewModel~RoleListViewModel";
  public static RoleDetail: string = "RoleDetail";
  public static AttributeSet: string = "AttributeSet";
  public static AttributeConfiguration: string = "AttributeConfiguration";
  public static Attribute: string = "Attribute";
  public static Form: string = "Form";
  public static FormControlGroup: string = "FormControlGroup";
  public static FormControl: string = "FormControl";
  public static Favorite: string = "Favorite";
  public static FreeFormTextParser: string = "_FreeFormTextParserViewModel~FreeFormTextParserViewModel";
  public static DataSource: string = "_DataSourceEditViewModel~DataSourceEditViewModel";
  public static DataSourceList: string = "_DataSourceListViewModel~DataSourceListViewModel";
  public static Query: string = "Query";
  public static QueryList: string = "_QueryListViewModel~QueryListViewModel";
  public static QueryExecutionRequest: string = "_QueryExecutionRequestEditViewModel~QueryExecutionRequestEditViewModel";
  public static QueryExecutionRequestOptions: string = "_QueryExecutionRequestOptionsViewModel~QueryExecutionRequestOptionsViewModel";
  public static QueryExecutionStatus: string = "_QueryExecutionStatusViewModel~QueryExecutionStatusViewModel";
  public static QueryEvent: string = "QueryEvent";
  public static HelpLink: string = "_HelpLinkEditViewModel~HelpLinkEditViewModel";
  public static Webhook: string = "_WebhookEditViewModel~WebhookEditViewModel";
  public static WebhookList: string = "_WebhookListViewModel~WebhookListViewModel";
  public static WebhookEvent: string = "_WebhookEventEditViewModel~WebhookEventEditViewModel";
  public static WebhookEventList: string = "_WebhookEventListViewModel~WebhookEventListViewModel";
  public static ApplicationInformation: string = "_ApplicationInformation";

  public static Watch: string = "Watch";
  public static WatchEvent: string = "WatchEvent";
  public static WatchEventList: string = "_WatchEventListViewModel~WatchEventListViewModel";
  public static WatchEventSystemAdd: string = "_WatchEventSystemAddViewModel~WatchEventSystemAddViewModel";
  public static Log: string = "_LogEditViewModel~LogEditViewModel";
  public static LogList: string = "_LogListViewModel~LogListViewModel";

  public static PartitionList: string = "_PartitionListViewModel~PartitionListViewModel";
  public static Partition: string = "_PartitionEditViewModel~PartitionEditViewModel";
  public static PartitionService: string = "_PartitionServiceEditViewModel~PartitionServiceEditViewModel";
  public static PartitionDomain: string = "_PartitionDomainEditViewModel~PartitionDomainEditViewModel";
  public static PartitionRoute: string = "_PartitionRouteEditViewModel~PartitionRouteEditViewModel";
  public static SubscriptionConfig: string = "_SubscriptionConfigViewModel~SubscriptionConfigViewModel";
  public static SubscriptionOption: string = "_SubscriptionOptionViewModel~SubscriptionOptionViewModel";
  public static SubscriptionRequest: string = "_SubscriptionSignUpRequestViewModel~SubscriptionSignUpRequestViewModel";

  public static Encryption: string = "_Encryption";
  public static EncryptionEcho: string = "_EncryptionEcho";
  public static ApiAccess: string = "ApiAccess";
  public static ApiAccessClient: string = "ApiAccessClient";
  public static PickListCategory: string = "PickListCategory";
  public static PickList: string = "PickList";
  public static PickListValue: string = "PickListValue";
  public static InputOptionsList: string = "_OptionsList";
  public static InputPickList: string = "_PickList-PickListValue";
  public static CustomPickList: string = "_CustomPickList-PickListValue";

  public static DashboardList: string = "_DashboardListViewModel~DashboardListViewModel";
  public static Dashboard: string = "_DashboardEditViewModel~DashboardEditViewModel";
  public static DashboardWidget: string = "_DashboardWidget";

  public static WalletSecurityAuthenticate: string = "_AuthenticatedUserViewModel~AuthenticatedUserViewModel";
  public static WalletRole: string = "_RoleEditViewModel~RoleEditViewModel";
  public static WalletRoleDetail: string = "_RoleDetailEditViewModel~RoleDetailEditViewModel";
  public static WalletApiAccess: string = "_ApiAccessEditViewModel~ApiAccessEditViewModel";
  public static WalletApiAccessClient: string = "_ApiAccessClientEditViewModel~ApiAccessClientEditViewModel";
  public static WalletContact: string = "_ContactEditViewModel~ContactEditViewModel";
  public static WalletContactExternalAuthentication: string = "_ContactExternalAuthenticationEditViewModel~ContactExternalAuthenticationEditViewModel";
  public static WalletContactAccess: string = "_ContactAccessEditViewModel~ContactAccessEditViewModel";
  public static WalletContactRole: string = "_ContactRoleEditViewModel~ContactRoleEditViewModel";
  public static WalletPaymentProvider: string = "_PaymentProviderEditViewModel~PaymentProviderEditViewModel";
  public static WalletPaymentProviderTrigger: string = "_PaymentProviderTriggerEditViewModel~PaymentProviderTriggerEditViewModel";
  public static WalletPaymentMethod: string = "_PaymentMethodEditViewModel~PaymentMethodEditViewModel";
  public static WalletPaymentTransaction: string = "_PaymentTransactionEditViewModel~PaymentTransactionEditViewModel";
  public static WalletPaymentTransactionSale: string = "_PaymentTransactionSaleAddViewModel~PaymentTransactionSaleAddViewModel";
  public static WalletPaymentTransactionRefund: string = "_PaymentTransactionRefundAddViewModel~PaymentTransactionRefundAddViewModel";
  public static WalletPaymentTransactionTrace: string = "_PaymentTransactionTraceDataViewModel~PaymentTransactionTraceDataViewModel";
  public static WalletPaymentNotification: string = "_PaymentNotificationAddViewModel~PaymentNotificationAddViewModel";
  public static WalletEncryptionKey: string = "_EncryptionViewModel~EncryptionViewModel";
  public static WalletEncryptionEcho: string = "_EncryptionEchoEditViewModel~EncryptionEchoEditViewModel";

  public static Number: string = "number";
  public static String: string = "string";
  public static None: string = "";

}


/**
Common UI layout helpers that we can inject in class="" attributes to give us the ability to tweak site layout in one location
*/
export class Layout {

  public static fullWidth: string = "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"; // edit-control-block
  public static split2column: string = "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"; // edit-control-block
  public static split3column: string = "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"; // edit-control-block
  public static split4column: string = "col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"; // edit-control-block
  public static split3largecolumn: string = "col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"; // edit-control-block
  public static split3flexcolumn: string = "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4";
  public static splitPercent25FlexColumn: string = "col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3";
  public static splitPercent75FlexColumn: string = "col-9 col-sm-12 col-md-9 col-lg-9 col-xl-9";

  public static split2label: string = "col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3";
  public static split2inputOffset: string = "offset-xs-3 offset-sm-3 offset-md-3 offset-lg-3 offset-xl-3";
  public static split2input: string = "col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9";
  public static split2inputMedium: string = "col-9 col-sm-9 col-md-6 col-lg-4 col-xl-4";
  public static split2inputShort: string = "col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2";
  public static split2inputTiny: string = "col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1";
  public static split2inputWithActions: string = "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6";
  public static split2inputMediumWithActions: string = "col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4";
  public static split2inputShortWithActions: string = "col-5 col-sm-5 col-md-2 col-lg-2 col-xl-2";
  public static split2inputTinyWithActions: string = "col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1";
  public static split2actions: string = "col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3";
  public static split2actionsMedium: string = "col-3 col-sm-3 col-md-5 col-lg-5 col-xl-5";
  public static split2actionsShort: string = "col-4 col-sm-4 col-md-7 col-lg-7 col-xl-7";
  public static split2actionsTiny: string = "col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8";

  public static percent15: string = "col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2";
  public static percent25: string = "col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3";
  public static percent33: string = "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4";
  public static percent50: string = "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6";
  public static percent66: string = "col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8";
  public static percent75: string = "col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9";
  public static percent85: string = "col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10";
  public static percent100: string = "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12";

  public static percent25Offset: string = "offset-md-3 offset-lg-3 offset-xl-3";
  public static percent33Offset: string = "offset-md-4 offset-lg-4 offset-xl-4";

  public static percent40: string = "col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5";
  public static percent60: string = "col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7";

  public static percent20: string = "col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2";
  public static percent80: string = "col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10";

  public static percent10: string = "col-2 col-sm-2 col-md-1 col-lg-1 col-md-1_5 col-lg-1_5";
  public static percent90: string = "col-10 col-sm-10 col-md-11 col-lg-11 col-md-10_5 col-lg-10_5";

  public static loadingIcon: string = "fas fa-spinner fa-spin";
  public static loadingText: string = "Loading...";
  public static workingText: string = "Working...";

}

/**
Constants for contact types.
*/
export class ContactType {

  public static Any: string = "";

  public static Customer: string = "C";
  public static Prospect: string = "P";
  public static Marketing: string = "M";
  public static Agent: string = "A";

  public static ServiceProvider: string = "S";
  public static Vendor: string = "V";
  public static RetailLocation: string = "R";
  public static Warehouse: string = "W";
  public static Location: string = "L";

  public static Directory: string = "D";
  public static ApiKey: string = "K";
  public static Group: string = "G";
  // Legacy replaced by group... public static Organization: string = "O";

  public static TaxAuthority: string = "X";
  public static ActivityContact: string = "Y";
  public static CollectionAgency: string = "$";
  public static System: string = "_";
  public static Contact: string = "T";

  public static description(contactType: string): string {
    if (!contactType) {
      return "Any";
    } else if (contactType === ContactType.Directory) {
      return "Directory";
    } else if (contactType === ContactType.ApiKey) {
      return "API Key";
    } else if (contactType === ContactType.Group) {
      return "Group";
    } else if (contactType === ContactType.Customer) {
      return "Customer";
    } else if (contactType === ContactType.Prospect) {
      return "Prospect";
    } else if (contactType === ContactType.Marketing) {
      return "Marketing Contact";
    } else if (contactType === ContactType.Agent) {
      return "Agent";
    } else if (contactType === ContactType.ServiceProvider) {
      return "Service Provider";
    } else if (contactType === ContactType.Vendor) {
      return "Vendor";
    } else if (contactType === ContactType.Warehouse) {
      return "Warehouse";
    } else if (contactType === ContactType.RetailLocation) {
      return "Retail Location";
    } else if (contactType === ContactType.Location) {
      return "Location";
    } else if (contactType === ContactType.TaxAuthority) {
      return "Tax Authority";
    } else if (contactType === ContactType.ActivityContact) {
      return "Activity Contact";
    } else if (contactType === ContactType.CollectionAgency) {
      return "Collection Agency";
    } else if (contactType === ContactType.System) {
      return "System";
    } else if (contactType === ContactType.Contact) {
      return "Contact";
    } else if (contactType === ContactType.Any) {
      return "Any";
    } else {
      return contactType;
    }
  }

  public static getPickList(...contactTypes: string[]): m5core.PickListSelectionViewModel[] {
    let list: m5core.PickListSelectionViewModel[] = [];
    if (contactTypes && contactTypes.length > 0) {
      contactTypes.forEach(type => {
        list.push(Object.assign(new m5core.PickListSelectionViewModel(), { Value: type }));
      });
    } else {
      list.push(Object.assign(new m5core.PickListSelectionViewModel(), { Value: ContactType.Directory }));
      list.push(Object.assign(new m5core.PickListSelectionViewModel(), { Value: ContactType.Customer }));
      list.push(Object.assign(new m5core.PickListSelectionViewModel(), { Value: ContactType.Any }));
      //} else if (contactType === ContactType.ApiKey) {
      //} else if (contactType === ContactType.Group) {
      //} else if (contactType === ContactType.Prospect) {
      //} else if (contactType === ContactType.Marketing) {
      //} else if (contactType === ContactType.Agent) {
      //} else if (contactType === ContactType.ServiceProvider) {
      //} else if (contactType === ContactType.Vendor) {
      //} else if (contactType === ContactType.Warehouse) {
      //} else if (contactType === ContactType.RetailLocation) {
      //} else if (contactType === ContactType.Location) {
      //} else if (contactType === ContactType.TaxAuthority) {
      //} else if (contactType === ContactType.ActivityContact) {
      //} else if (contactType === ContactType.CollectionAgency) {
      //} else if (contactType === ContactType.Contact) {
    }
    // Now step through our list and assign our pick list id and display text for each contact type
    list.forEach(item => {
      item.PickListId = "_ContactType";
      item.DisplayText = ContactType.description(item.Value);
    });
    return list;
  }

}


export class ContactScope {

  public static InheritedFromGroup: string = "I";
  public static Normal: string = "N";
  public static Administrator: string = "A";
  public static Limited: string = "L";
  public static Terminated: string = "T";

  public static description(contactScope: string): string {
    if (!contactScope) {
      return "Inherited from Group";
    } else if (contactScope === ContactScope.InheritedFromGroup || contactScope.toUpperCase() === `Scope:${ContactScope.InheritedFromGroup}`.toUpperCase()) {
      return "Inherited from Group";
    } else if (contactScope === ContactScope.Administrator || contactScope.toUpperCase() === `Scope:${ContactScope.Administrator}`.toUpperCase()) {
      return "Administrator";
    } else if (contactScope === ContactScope.Limited || contactScope.toUpperCase() === `Scope:${ContactScope.Limited}`.toUpperCase()) {
      return "Limited";
    } else if (contactScope === ContactScope.Terminated || contactScope.toUpperCase() === `Scope:${ContactScope.Terminated}`.toUpperCase()) {
      return "Terminated";
    } else if (contactScope === ContactScope.Normal || contactScope.toUpperCase() === `Scope:${ContactScope.Normal}`.toUpperCase()) {
      return "Normal";
    } else {
      return contactScope;
    }
  }

}


/**
Constants for contact preferences.
*/
export class ContactPreference {
  public static ApplicationSecurity: string = "ApplicationSecurity";
  public static ApplicationSettings: string = "ApplicationSettings";
  public static HelpDisableAutoOpen: string = "HelpDisableAutoOpen";
  public static TableConfig: string = "TableConfig";
}


export class Setting {
  public static CategoryGlobalPartitionZero = "GlobalPartitionZero";
  public static AttributeNotificationMessageStandard = "NotificationMessageStandard";
}


export class PickList {
  // Pick lists defined in our pick list table
  public static WebLanguage: string = "WebLanguage";
  public static AccountClass: string = "AccountClass";
  public static AccountType: string = "AccountType";
  public static AccountGroup: string = "AccountGroup";
  public static AssetCategory: string = "AssetCategory";
  public static AssetClass: string = "AssetClass";
  public static AssetGroup: string = "AssetGroup";
  public static ContactAddressType: string = "ContactAddressType";
  public static CaseType: string = "CaseType";
  public static CaseCategory: string = "CaseCategory";
  public static CasePublishCategory: string = "CasePublishCategory";
  public static CaseGroup: string = "CaseGroup";
  public static CasePriority: string = "CasePriority";
  public static CaseStatus: string = "CaseStatus";
  public static CaseProgress: string = "CaseProgress";
  public static CaseOutcome: string = "CaseOutcome";
  public static CaseRiskLevel: string = "CaseRiskLevel";
  public static CaseAccessRole: string = "CaseAccessRole";
  public static TaskCategory: string = "TaskCategory";
  public static TaskStatus: string = "TaskStatus";
  public static MarketType: string = "MarketType";
  public static ProcessTemplateType: string = "ProcessTemplateType";
  public static ProcessTemplateCategory: string = "ProcessTemplateCategory";
  public static QueryCategory: string = "QueryCategory";
  public static FilterCategory: string = "FilterCategory";
  public static NotificationContactType: string = "NotificationContactType";
  public static NotificationGroupCategory: string = "NotificationGroupCategory";
  public static NotificationMessageStandard: string = "NotificationMessageStandard";
  public static Printer: string = "Printer";

  public static UnitOfMeasureType = "UnitOfMeasureType";

  public static NetworkElementGrouping = "NetworkElementGrouping";
  public static NetworkElementType = "NetworkElementGrouping";
  public static NetworkElementRole = "NetworkElementGrouping";
  public static NetworkElementNetwork = "NetworkElementGrouping";
  public static NetworkElementOnNetGroup = "NetworkElementGrouping";
  public static NetworkPortGroupType = "NetworkPortGroupType";
  public static NetworkPortGroupRole = "NetworkPortGroupRole";
  public static NetworkPortType = "NetworkPortType";
  public static NetworkPortRole = "NetworkPortRole";

  public static PackageType: string = "PackageType";
  public static PackageCode: string = "PackageCode";
  public static PackageClass: string = "PackageClass";

  public static VoucherVisibilityType: string = "VoucherVisibilityType";

  public static SalesStage = "SalesStage";
  public static SalesCompetitor = "SalesCompetitor";
  public static SalesResultReason = "SalesResultReason";

  public static TaxItemCode: string = "TaxItemCode";
  public static TaxCustomerType: string = "TaxCustomerType";
  public static TaxServiceProviderType: string = "TaxServiceProviderType";
  public static TaxVendorType: string = "TaxVendorType";
  public static TaxCategory: string = "TaxCategory";
  public static TaxType: string = "TaxType";
  public static TaxAuthorityType: string = "TaxAuthorityType";
  public static TaxLocation: string = "TaxLocation";
  public static VoucherType: string = "VoucherType";

  public static ItemCategory: string = "ItemCategory";
  public static ItemType: string = "ItemType";
  public static ItemGroup: string = "ItemGroup";
  public static ItemClass: string = "ItemClass";
  public static GeneralLedgerCode: string = "GeneralLedgerCode";

  public static SensitiveInformationCategory: string = "SensitiveInformationCategory";

  public static EpaymentRefundReason: string = "EpaymentRefundReason";

  // A leading single underscore is for pick lists built using the primary key and description columns from a table for picking a FK
  // or for other internal lists.
  public static _Modules: string = "_Modules";
  public static _CaseTemplate: string = "_CaseTemplate";
  public static _Case: string = "_Cases"; // plural table name
  public static _ProcessTemplate: string = "_ProcessTemplate";
  public static _Webhook: string = "_Webhook";
  public static _Asset: string = "_Asset";
  public static _Script: string = "_Script";
  public static _Report: string = "_Report";
  public static _ReportTemplate: string = "_ReportTemplate";
  public static _InvoiceMessage: string = "_InvoiceMessage";
  public static _Query: string = "_Query";
  public static _QueryOutputConfiguration: string = "_QueryOutputConfiguration";
  public static _Form: string = "_Form";
  public static _Role: string = "_Role";
  public static _Item: string = "_Item";
  public static _BillingAccount: string = "_BillingAccount";
  public static _BillingProfile: string = "_BillingProfile";
  public static _BillingReportProfile: string = "_BillingReportProfile";
  public static _RatingProfile: string = "_RatingProfile";
  public static _TelecomNumberingPlan: string = "_TelecomNumberingPlan";
  public static _UtcOffset: string = "_UtcOffset";
  public static _TimeZone: string = "_TimeZone";
  public static _Currency: string = "_Currency";
  public static _JobType: string = "_JobType";
  public static _Job: string = "_Job";
  public static _JobScheduled: string = "_JobScheduled";
  public static _FtpServer: string = "_FtpServer";
  public static _FileServer: string = "_FileServer";
  public static _Object_DataSource: string = "_Object_DataSource";
  public static _Object_SecurityPolicy: string = "_Object_SecurityPolicy";
  public static _Object_MailServer: string = "_Object_MailServer";
  public static _Object_MailAddressAction: string = "_Object_MailAddressAction";
  public static _Object_HelpLink: string = "_Object_HelpLink";
  public static _Object_ServerResourceAlarmRule: string = "_Object_ServerResourceAlarmRule";
  public static _Object_PartitionRoute: string = "_Object_PartitionRoute";
  public static _Object_AssetLicense: string = "_Object_AssetLicense";
  public static _Object_Certificate: string = "_Object_Certificate";
  public static _Object_DataPartitionSchema: string = "_Object_DataPartitionSchema";
  public static _Object_DataTableSupport: string = "_Object_DataTableSupport";
  public static _Object_DataImportDefinition: string = "_Object_DataImportDefinition";
  public static _Object_FileServer: string = "_Object_FileServer";
  public static _Object_ProxyServer: string = "_Object_ProxyServer";
  public static _Object_FreeFormTextParser: string = "_Object_FreeFormTextParser";
  public static _Object_ReportCompilerLibraryGroupConfiguration: string = "_Object_ReportCompilerLibraryGroupConfiguration";
  public static _Object_ReportCompilerTemplateTypeConfiguration: string = "_Object_ReportCompilerTemplateTypeConfiguration";
  public static _DataSource: string = "_DataSource";
  public static _ApplicationServer: string = "_ApplicationServer";
  public static _Printer: string = "_Printer";
  public static _Modem: string = "_Modem";
  public static _IpAddress: string = "_IpAddress";
  public static _IpAddressV4: string = "_IpAddressV4";
  public static _IpAddressV6: string = "_IpAddressV6";
  public static _Filter: string = "_Filter";
  public static _PickList: string = "_PickList";
  public static _SystemPickListId: string = "_SystemPickListId";
  public static _NetworkElement: string = "_NetworkElement";
  public static _NotificationContact: string = "_NotificationContact";
  public static _NotificationGroup: string = "_NotificationGroup";
  public static _NotificationMessage: string = "_NotificationMessage";
  public static _NotificationMessageExternalAssetId: string = "_NotificationMessageExternalAssetId";
  public static _NotificationEventYYYYMM: string = "_NotificationEventYYYYMM";
  public static _NotificationYYYYMM: string = "_NotificationYYYYMM";
  public static _InventoryType: string = "_InventoryType";
  public static _InventoryTypeVersion: string = "_InventoryTypeVersion";
  public static _InventoryQuantityType: string = "_InventoryQuantityType";
  public static _AlarmRule: string = "_AlarmRule";
  public static _Directory: string = "_Directory";
  public static _Group: string = "_Group";
  public static _Vendor: string = "_Vendor";
  public static _Warehouse: string = "_Warehouse";
  public static _Contact: string = "_Contact";
  public static _SystemDrawingColor: string = "_SystemDrawingColor";
  public static _DataStoreObjectName: string = "_DataStoreObjectName";
  public static _DataStoreTableNameNormalized: string = "_DataStoreTableNameNormalized";
  public static _PartitionService: string = "_PartitionService";
  public static _Geocode: string = "_Geocode";
  public static _TaxConfiguration: string = "_TaxConfiguration";
  public static _TaxMatrix: string = "_TaxMatrix";
  public static _TelecomLocationProfile: string = "_TelecomLocationProfile";
  public static _ExternalUsageDataLayout: string = "_ExternalUsageDataLayout";
  public static _UsageDataSource: string = "_UsageDataSource";
  public static _UsageDataSourceIdExternalId: string = "_UsageDataSourceIdExternalId";
  public static _LogAction: string = "_LogAction";
  public static _RateCenter: string = "_RateCenter";
  public static _Package: string = "_Package";
  public static _PaymentProvider: string = "_PaymentProvider";

  // Custom RC pick lists
  public static _RC_Custom_PickLists: string = "_RC_Custom_PickLists";
  public static _RC_Asset_Visibility: string = "_RC_Asset_Visibility";
  public static _RC_CaseContactDefaultContactType: string = "_RC_CaseContactDefaultContactType";
  public static _RC_CaseContactLink: string = "_RC_CaseContactLink";
  public static _RC_SubjectMatterType: string = "_RC_SubjectMatterType";
  public static _RC_IndustryLibraryType: string = "_RC_IndustryLibraryType";
  public static _RC_SystemPickListId: string = "_RC_SystemPickListId";

  // A double leading underscore is for pick lists built using the data model defined options for a table and column
  public static __CONTACT_SCOPE: string = "__CONTACT_SCOPE";

  public static __ServerResource_Type: string = "__ServerResource_Type";

  public static __SalesOpportunity_Result: string = "__SalesOpportunity_Result";

  public static __AlarmRule_RuleType: string = "__AlarmRule_RuleType";
  public static __AlarmRule_RuleFormat: string = "__AlarmRule_RuleFormat";
  public static __AlarmRule_NotificationResetEventType: string = "__AlarmRule_NotificationResetEventType"

  public static __RoleDetail_SecurityAreaType: string = "__RoleDetail_SecurityAreaType";
  public static __RoleDetail_SecurityAreaTable: string = "__RoleDetail_SecurityAreaTable";
  public static __RoleDetail_SecurityAreaTableGroup: string = "__RoleDetail_SecurityAreaTableGroup";
  public static __RoleDetail_SecurityAreaReport: string = "__RoleDetail_SecurityAreaReport";
  public static __RoleDetail_SecurityAreaReportGroup: string = "__RoleDetail_SecurityAreaReportGroup";
  public static __RoleDetail_SecurityAreaPermissionArea: string = "__RoleDetail_SecurityAreaPermissionArea";
  public static __PickList_PickListSort: string = "__PickList_PickListSort";

  public static __EnumInt_GroupMembershipApproval: string = "__EnumInt_GroupMembershipApproval";
  public static __EnumInt_PaymentMethodType: string = "__EnumInt_PaymentMethodType";
  public static __EnumInt_SupportUrlAuthenticationTokenType: string = "__EnumInt_SupportUrlAuthenticationTokenType";
  public static __EnumInt_BrandId: string = "__EnumInt_BrandId";
  public static __EnumInt_TypeCode: string = "__EnumInt_TypeCode";
  public static __EnumString_BrandId: string = "__EnumString_BrandId";
  public static __EnumString_SubscriptionOptionPriceTimePeriod: string = "__EnumString_SubscriptionOptionPriceTimePeriod";
  public static __EnumString_SensitiveInformationMaskType: string = "__EnumString_SensitiveInformationMaskType";
  public static __EnumString_SensitiveInformationSanitizeType: string = "__EnumString_SensitiveInformationSanitizeType";
  public static __EnumString_ScriptLanguage: string = "__EnumString_ScriptLanguage";
  public static __EnumString_ScriptLanguageServerCommon: string = "__EnumString_ScriptLanguageServerCommon";
  public static __EnumString_ScriptLanguageClientCommon: string = "__EnumString_ScriptLanguageClientCommon";
  public static __EnumString_ExternalServiceInformationSharingLevel: string = "__EnumString_ExternalServiceInformationSharingLevel";
  public static __EnumString_IB_Core_Web_Method: string = "__EnumString_IB.Core.Web.Method";
  public static __EnumString_InputControlTypeOption: string = "__EnumString_InputControlTypeOption";
  public static __EnumString_InputControlWidthOption: string = "__EnumString_InputControlWidthOption";
  public static __EnumString_PropertyMetaDataAction: string = "__EnumString_PropertyMetaDataAction";
  public static __EnumString_PropertyMetaDataMapMatchMode: string = "__EnumString_PropertyMetaDataMapMatchMode";
  public static __EnumString_PropertyMetaDataMapValueMode: string = "__EnumString_PropertyMetaDataMapValueMode";
  public static __EnumString_FormCustomizationSectionPresentationStyle: string = "__EnumString_FormCustomizationSectionPresentationStyle";
  public static __EnumString_TypeCode: string = "__EnumString_TypeCode";
  public static __EnumString_HelpLinkItemType: string = "__EnumString_HelpLinkItemType";
  public static __EnumString_HelpLinkItemTextType: string = "__EnumString_HelpLinkItemTextType";
  public static __EnumString_HelpLinkItemMessageWindowSize: string = "__EnumString_HelpLinkItemMessageWindowSize";
  public static __EnumString_AssetArticleGroupStructureChildContentLinkLocation: string = "__EnumString_AssetArticleGroupStructureChildContentLinkLocation";

  // Jobs
  public static __EnumInt_JobDatabaseBackupType: string = "__EnumInt_JobDatabaseBackupType";
  public static __EnumInt_JobDatabaseBackupCompressionType: string = "__EnumInt_JobDatabaseBackupCompressionType";
  public static __EnumInt_JobDirectorySyncDirectoryProvider: string = "__EnumInt_JobDirectorySyncDirectoryProvider";
  public static __EnumInt_JobDirectorySyncDirectionOptions: string = "__EnumInt_JobDirectorySyncDirectionOptions";
  public static __EnumInt_JobDirectorySyncAction: string = "__EnumInt_JobDirectorySyncAction";
  public static __EnumInt_JobCheckRadiusRequestType: string = "__EnumInt_JobCheckRadiusRequestType";
  public static __EnumInt_JobModemBpsRateOptions: string = "__EnumInt_JobModemBpsRateOptions";
  public static __EnumInt_JobModemDataBitsOptions: string = "__EnumInt_JobModemDataBitsOptions";
  public static __EnumInt_JobModemStopBitsOptions: string = "__EnumInt_JobModemStopBitsOptions";
  public static __EnumInt_JobModemDownloadParitySetting: string = "__EnumInt_JobModemDownloadParitySetting";
  public static __EnumInt_JobModemDownloadProtocolSetting: string = "__EnumInt_JobModemDownloadProtocolSetting";
  public static __EnumInt_JobHttpDownloadFileExistsAction: string = "__EnumInt_JobHttpDownloadFileExistsAction";
  public static __EnumInt_JobHttpDownloadPostDownloadAction: string = "__EnumInt_JobHttpDownloadPostDownloadAction";
  public static __EnumInt_JobFtpClientFileExistsAction: string = "__EnumInt_JobFtpClientFileExistsAction";
  public static __EnumInt_JobFtpClientPostDownloadAction: string = "__EnumInt_JobFtpClientPostDownloadAction";
  public static __EnumInt_JobFtpClientDirection: string = "__EnumInt_JobFtpClientDirection";
  public static __EnumInt_JobFtpClientRenameSourceOption: string = "__EnumInt_JobFtpClientRenameSourceOption";
  public static __EnumInt_JobRatingCacheSetting: string = "__EnumInt_JobRatingCacheSetting";
  public static __EnumInt_QueryExecutionRequestRecipientType: string = "__EnumInt_QueryExecutionRequestRecipientType";
  public static __EnumInt_QueryExecutionRequestDuplicatePreventionTypeOption: string = "__EnumInt_QueryExecutionRequestDuplicatePreventionTypeOption";
  public static __EnumInt_JobReportRecipientTypeOptions: string = "__EnumInt_JobReportRecipientTypeOptions";
  public static __EnumInt_JobReportTypeOptions: string = "__EnumInt_JobReportTypeOptions";
  public static __EnumInt_JobReportDateRangeType: string = "__EnumInt_JobReportDateRangeType";
  public static __EnumInt_JobReportOutputTypeOptions: string = "__EnumInt_JobReportOutputTypeOptions";
  public static __EnumInt_JobReportFileFormatOptions: string = "__EnumInt_JobReportFileFormatOptions";
  public static __EnumInt_JobReportDuplexOptions: string = "__EnumInt_JobReportDuplexOptions";
  public static __EnumString_JobRunProcessWindowStyleOptions: string = "__EnumString_JobRunProcessWindowStyleOptions";
  public static __EnumString_JobDatabaseRestoreRestoreTypeOptions: string = "__EnumString_JobDatabaseRestoreRestoreTypeOptions";
  public static __EnumMappedString_JobDatabaseRestoreSortOptions: string = "__EnumMappedString_JobDatabaseRestoreSortOptions";
  public static __EnumMappedString_JobDatabaseRestoreOnSuccessfulRestoreOptions: string = "__EnumMappedString_JobDatabaseRestoreOnSuccessfulRestoreOptions";
  public static __EnumString_JobDataCollectionTransmissionProcessorOptions: string = "__EnumString_JobDataCollectionTransmissionProcessorOptions";
  public static __EnumString_JobDataCollectionTransmissionSourceDestinationOptions: string = "__EnumString_JobDataCollectionTransmissionSourceDestinationOptions";
  public static __EnumInt_JobDataCollectionTransmissionSerialBaudRateOptions: string = "__EnumInt_JobDataCollectionTransmissionSerialBaudRateOptions";
  public static __EnumString_JobDataCollectionTransmissionSerialParityOptions: string = "__EnumString_JobDataCollectionTransmissionSerialParityOptions";
  public static __EnumInt_JobDataCollectionTransmissionSerialDataBitsOptions: string = "__EnumInt_JobDataCollectionTransmissionSerialDataBitsOptions";
  public static __EnumString_JobDataCollectionTransmissionSerialStopBitsOptions: string = "__EnumString_JobDataCollectionTransmissionSerialStopBitsOptions";
  public static __EnumString_JobDataCollectionTransmissionSerialFlowControlOptions: string = "__EnumString_JobDataCollectionTransmissionSerialFlowControlOptions";

  public static __CachePackageSignup_Status: string = "__CachePackageSignup_Status";
  public static __CachePackageSignup_AssociationType: string = "__CachePackageSignup_AssociationType";
  public static __CachePackageSignup_PaymentMethodType: string = "__CachePackageSignup_PaymentMethodType";

  public static __Asset_AssetType: string = "__Asset_AssetType";
  public static __Asset_SystemAssetGroup: string = "__Asset_SystemAssetGroup";
  public static __Asset_ActionType: string = "__Asset_ActionType";
  public static __Asset_Visibility: string = "__Asset_Visibility";
  public static __Asset_ShowAddedByInformation: string = "__Asset_ShowAddedByInformation";

  public static __Form_AddMode: string = "__Form_AddMode";
  public static __Form_AddModeRegistration: string = "__Form_AddModeRegistration";
  public static __Form_AddSaveAction: string = "__Form_AddSaveAction";
  public static __Form_AddCancelAction: string = "__Form_AddCancelAction";
  public static __Form_EditMode: string = "__Form_EditMode";
  public static __Form_EditSaveAction: string = "__Form_EditSaveAction";
  public static __Form_EditCancelAction: string = "__Form_EditCancelAction";
  public static __Form_LabelLayout: string = "__Form_LabelLayout";
  public static __FormControlGroup_GroupScope: string = "__FormControlGroup_GroupScope";
  public static __FormControlGroup_GroupType: string = "__FormControlGroup_GroupType";
  public static __FormControlGroup_PresentationStyle: string = "__FormControlGroup_PresentationStyle";
  public static __FormControlGroup_LabelLayout: string = "__FormControlGroup_LabelLayout";
  public static __FormControl_PropertyDataType: string = "__FormControl_PropertyDataType";
  public static __FormControl_ControlType: string = "__FormControl_ControlType";
  public static __FormControl_ControlOption: string = "__FormControl_ControlOption";
  public static __FormControl_ControlScope: string = "__FormControl_ControlScope";
  public static __FormControl_Alignment: string = "__FormControl_Alignment";
  public static __FormControl_Width: string = "__FormControl_Width";
  public static __FormControl_LabelLayout: string = "__FormControl_LabelLayout";
  public static __FormControl_FilterType: string = "__FormControl_FilterType";
  public static __FormControl_FilterMatchMode: string = "__FormControl_FilterMatchMode";

  public static __Association_AssociationType: string = "__Association_AssociationType";
  public static __Association_Days: string = "__Association_Days";
  public static __Association_Status: string = "__Association_Status";

  public static __Case_BillingType: string = "__Case_BillingType";
  public static __Case_BudgetType: string = "__Case_BudgetType";

  public static __CaseTemplate_DueTrigger: string = "__CaseTemplate_DueTrigger";
  public static __CaseTemplate_CaseOwnership: string = "__CaseTemplate_CaseOwnership";
  public static __CaseTemplate_CaseAssignment: string = "__CaseTemplate_CaseAssignment";
  public static __CaseTemplate_SupervisorAssignment: string = "__CaseTemplate_SupervisorAssignment";
  public static __CaseTemplate_ReviewAssignment: string = "__CaseTemplate_ReviewAssignment";
  public static __CaseTemplate_AllowReopen: string = "__CaseTemplate_AllowReopen";
  public static __CaseTemplate_CancelledFeatures: string = "__CaseTemplate_CancelledFeatures";
  public static __CaseTemplate_DuplicateFeatures: string = "__CaseTemplate_DuplicateFeatures";
  public static __CaseTemplate_SupportDetails: string = "__CaseTemplate_SupportDetails";
  public static __CaseTemplate_SupportSummary: string = "__CaseTemplate_SupportSummary";
  public static __CaseTemplate_SupportResolution: string = "__CaseTemplate_SupportResolution";

  public static __TaskListTemplate_Enabled: string = "__TaskListTemplate_Enabled";
  public static __TaskListTemplate_Required: string = "__TaskListTemplate_Required";
  public static __TaskListTemplate_CompletedTaskMode: string = "__TaskListTemplate_CompletedTaskMode";
  public static __TaskListTemplate_SignatureType: string = "__TaskListTemplate_SignatureType";
  public static __TaskTemplate_Priority: string = "__TaskTemplate_Priority";
  public static __TaskTemplate_Required: string = "__TaskTemplate_Required";
  public static __TaskTemplate_SignatureType: string = "__TaskTemplate_SignatureType";
  public static __TaskTemplate_EstimatedStartAlternateTaskIdTrigger: string = "__TaskTemplate_EstimatedStartAlternateTaskIdTrigger";
  public static __TaskTemplate_EstimatedCloseAlternateTaskIdTrigger: string = "__TaskTemplate_EstimatedCloseAlternateTaskIdTrigger";
  public static __TaskTemplate_EndDateTreatment: string = "__TaskTemplate_EndDateTreatment";
  public static __TaskTemplate_AssignmentFlag: string = "__TaskTemplate_AssignmentFlag";
  public static __TaskTemplate_AssignedByFlag: string = "__TaskTemplate_AssignedByFlag";
  public static __TaskTemplate_SupervisorFlag: string = "__TaskTemplate_SupervisorFlag";
  public static __TaskTemplate_CanCompleteFlags: string = "__TaskTemplate_CanCompleteFlags";
  public static __Task_Priority: string = "__Task_Priority";
  public static __Task_SystemTaskProcessStatus: string = "__Task_SystemTaskProcessStatus";

  public static __Process_Status: string = "__Process_Status";
  public static __ProcessStep_Status: string = "__ProcessStep_Status";

  public static __ProcessTemplate_LogType: string = "__ProcessTemplate_LogType";
  public static __ProcessTemplate_ValidProcessTriggers: string = "__ProcessTemplate_ValidProcessTriggers";
  public static __ProcessTemplateStep_State: string = "__ProcessTemplateStep_State";
  public static __ProcessTemplateStep_LogType: string = "__ProcessTemplateStep_LogType";
  public static __ProcessTemplateStep_StepActionType: string = "__ProcessTemplateStep_StepActionType";
  public static __ProcessTemplateStep_WaitStateAction: string = "__ProcessTemplateStep_WaitStateAction";
  public static __ProcessTemplateStep_RetryAction: string = "__ProcessTemplateStep_RetryAction";
  public static __ProcessTemplateStep_OnStepSuccess: string = "__ProcessTemplateStep_OnStepSuccess";
  public static __ProcessTemplateStep_OnStepFailure: string = "__ProcessTemplateStep_OnStepFailure";

  public static __RatingProfile_ProfileType: string = "__RatingProfile_ProfileType";
  public static __RatingProfile_DelayedRatingMethod: string = "__RatingProfile_DelayedRatingMethod";
  public static __RatingProfile_DelayedRatingBasis: string = "__RatingProfile_DelayedRatingBasis";
  public static __RatingProfile_FlagMissingFullTarget: string = "__RatingProfile_FlagMissingFullTarget";
  public static __RatingProfile_FlagMissingPartialTarget: string = "__RatingProfile_FlagMissingPartialTarget";
  public static __RatingProfile_FlagMissingFullSource: string = "__RatingProfile_FlagMissingFullSource";
  public static __RatingProfile_FlagMissingPartialSource: string = "__RatingProfile_FlagMissingPartialSource";
  public static __RatingProfile_FlagMissingFullCostCenter: string = "__RatingProfile_FlagMissingFullCostCenter";
  public static __RatingProfile_FlagMissingPartialCostCenter: string = "__RatingProfile_FlagMissingPartialCostCenter";
  public static __RatingProfile_FlagMissingServiceIdentificationForData: string = "__RatingProfile_FlagMissingServiceIdentificationForData";
  public static __RatingProfile_FlagMissingServiceIdentificationForVideo: string = "__RatingProfile_FlagMissingServiceIdentificationForVideo";
  public static __RatingProfile_FlagMissingServiceIdentificationForContent: string = "__RatingProfile_FlagMissingServiceIdentificationForContent";
  public static __RatingProfile_FlagMissingHolidayRate: string = "__RatingProfile_FlagMissingHolidayRate";
  public static __RatingProfile_FlagZeroCostIncoming: string = "__RatingProfile_FlagZeroCostIncoming";
  public static __RatingProfile_FlagZeroCostOutgoing: string = "__RatingProfile_FlagZeroCostOutgoing";
  public static __RatingProfile_FlagZeroCostInternal: string = "__RatingProfile_FlagZeroCostInternal";
  public static __RatingProfile_FlagZeroCostExternal: string = "__RatingProfile_FlagZeroCostExternal";
  public static __RatingProfile_FlagZeroCostCompleted: string = "__RatingProfile_FlagZeroCostCompleted";
  public static __RatingProfile_FlagZeroCostIncomplete: string = "__RatingProfile_FlagZeroCostIncomplete";
  public static __RatingProfile_FlagZeroCostNoUnits: string = "__RatingProfile_FlagZeroCostNoUnits";
  public static __RatingProfile_CheckOnNetStatusByNumber: string = "__RatingProfile_CheckOnNetStatusByNumber";
  public static __RatingProfile_VisibilityCostCenterType: string = "__RatingProfile_VisibilityCostCenterType";

  public static __RatingGroup_GroupUsage: string = "__RatingGroup_GroupUsage";
  public static __RatingGroup_RateDescriptionUse: string = "__RatingGroup_RateDescriptionUse";

  public static __RatingRate_UnitType: string = "__RatingRate_UnitType";
  public static __RatingRate_SurchargeUnitType: string = "__RatingRate_SurchargeUnitType";
  public static __RatingRate_Days1: string = "__RatingRate_Days1";
  public static __RatingRate_Days2: string = "__RatingRate_Days2";
  public static __RatingRate_Days3: string = "__RatingRate_Days3";

  public static __RatingStep_RatingStepCode: string = "__RatingStep_RatingStepCode"
  public static __RatingStep_RatingStepCodeSource: string = "__RatingStep_RatingStepCodeSource"
  public static __RatingStep_TierFromZoneSourceFlag: string = "__RatingStep_TierFromZoneSourceFlag"
  public static __RatingStep_TierFromZoneTargetFlag: string = "__RatingStep_TierFromZoneTargetFlag"

  public static __RatingSurchargeDiscount_RateCodeUse: string = "__RatingSurchargeDiscount_RateCodeUse";

  public static __RatingZone_SourceLocationType: string = "__RatingZone_SourceLocationType";
  public static __RatingZone_SourceLocation: string = "__RatingZone_SourceLocation";
  public static __RatingZone_TargetLocationType: string = "__RatingZone_TargetLocationType";
  public static __RatingZone_TargetLocation: string = "__RatingZone_TargetLocation";

  public static __AttributeSet_AttributeSetType: string = "__AttributeSet_AttributeSetType";
  public static __AttributeConfiguration_DataType: string = "__AttributeConfiguration_DataType";

  public static __Query_Visibility: string = "__Query_Visibility";
  //public static __Query_PersistResults: string = "__Query_PersistResults";

  public static __NotificationGroup_NotificationContactMethod: string = "__NotificationGroup_NotificationContactMethod";
  public static __NotificationGroupDetail_Method: string = "__NotificationGroupDetail_Method";
  public static __NotificationGroupDetail_Type: string = "__NotificationGroupDetail_Type";
  public static __NotificationGroupDetail_NotificationRecipientType: string = "__NotificationGroupDetail_NotificationRecipientType";
  public static __NotificationGroupDetail_BroadcastGroup: string = "__NotificationGroupDetail_BroadcastGroup";
  public static __NotificationEventYYYYMM_Type: string = "__NotificationEventYYYYMM_Type";
  public static __NotificationYYYYMM_Method: string = "__NotificationYYYYMM_Method";
  public static __NotificationYYYYMM_Status: string = "__NotificationYYYYMM_Status";

  public static __ApiAccess_EncryptionType: string = "__ApiAccess_EncryptionType";

  public static __SyncPublisherArticleType_ArticleType: string = "__SyncPublisherArticleType_ArticleType";
  public static __SyncPublisherArticleType_SyncMode: string = "__SyncPublisherArticleType_SyncMode";

  public static __Webhook_ContentType: string = "__Webhook_ContentType";
  public static __Webhook_SignatureData: string = "__Webhook_SignatureData";

  public static __ContactGroupMembership_Status: string = "__ContactGroupMembership_Status";

  public static __Inventory_AssociationType: string = "__Inventory_AssociationType";
  public static __InventoryType_VersionReleaseFrequency: string = "__InventoryType_VersionReleaseFrequency";
  public static __InventoryTypeVersion_ReleaseType: string = "__InventoryTypeVersion_ReleaseType";

  public static __BillingTransaction_TransactionType: string = "__BillingTransaction_TransactionType";
  public static __BillingTransaction_Status: string = "__BillingTransaction_Status";
  public static __BillingTransactionDetail_Status: string = "__BillingTransactionDetail_Status";

  public static __BillingAccount_BillingAccountType: string = "__BillingAccount_BillingAccountType";
  public static __BillingAccount_Status: string = "__BillingAccount_Status";
  public static __BillingAccount_ServiceType: string = "__BillingAccount_ServiceType";
  public static __BillingAccount_AgentPlanId: string = "__BillingAccount_AgentPlanId";
  public static __BillingAccount_TermsDescription: string = "__BillingAccount_TermsDescription";
  public static __BillingAccount_BillingCycle: string = "__BillingAccount_BillingCycle";
  public static __BillingAccount_PrintZeroAmountInvoice: string = "__BillingAccount_PrintZeroAmountInvoice";
  public static __BillingAccount_InOutCity: string = "__BillingAccount_InOutCity";
  public static __BillingAccount_InOutLocal: string = "__BillingAccount_InOutLocal";
  public static __BillingAccount_Taxable: string = "__BillingAccount_Taxable";

  public static __BillingProfile_VisibilityCostCenterType: string = "__BillingProfile_VisibilityCostCenterType";
  public static __BillingProfile_ServiceType: string = "__BillingProfile_ServiceType";
  public static __BillingProfile_BillingCycle: string = "__BillingProfile_BillingCycle";
  public static __BillingProfile_AlwaysInvoiceFlag: string = "__BillingProfile_AlwaysInvoiceFlag";
  public static __BillingProfile_PrintZeroAmountInvoice: string = "__BillingProfile_PrintZeroAmountInvoice";
  public static __BillingProfile_Taxable: string = "__BillingProfile_Taxable";

  public static __BillingReportProfile_VisibilityCostCenterType: string = "__BillingReportProfile_VisibilityCostCenterType";

  public static __Item_ServiceType: string = "__Item_ServiceType";
  public static __Item_CommissionMaximumCheck: string = "__Item_CommissionMaximumCheck";
  public static __Item_CalculationType: string = "__Item_CalculationType";
  public static __Item_CalculationFixedBillingPeriod: string = "__Item_CalculationFixedBillingPeriod";
  public static __Item_CalculationFixedProrate: string = "__Item_CalculationFixedProrate";
  public static __Item_CalculationFixedConsolidate: string = "__Item_CalculationFixedConsolidate";
  public static __Item_CalculationPercentFlag: string = "__Item_CalculationPercentFlag";
  public static __Item_CalculationPercentConsolidate: string = "__Item_CalculationPercentConsolidate";
  public static __Item_CalculationTaxConsolidate: string = "__Item_CalculationTaxConsolidate";
  public static __Item_CalculationActivityTypeList: string = "__Item_CalculationActivityTypeList";
  public static __Item_CalculationActivityTypeQuantityProperty: string = "__Item_CalculationActivityTypeQuantityProperty";
  public static __Item_CalculationActivityTypeRateFlag: string = "__Item_CalculationActivityTypeRateFlag";
  public static __ItemRate_CommissionMaximumCheck: string = "__ItemRate_CommissionMaximumCheck";
  public static __ItemRate_RateCalculationFlag: string = "__ItemRate_RateCalculationFlag";
  public static __ItemRate_SurchargeCalculationFlag: string = "__ItemRate_SurchargeCalculationFlag";
  public static ___ItemRate_RateId: string = "___ItemRate_RateId";

  public static __Partition_Status: string = "__Partition_Status";
  public static __PartitionService_Status: string = "__PartitionService_Status";
  public static __PartitionDomain_Brand: string = "__PartitionDomain_Brand";

  public static __PaymentMethodCardType_AccountNumberValidationType: string = "__PaymentMethodCardType_AccountNumberValidationType";
  public static __PaymentProvider_ContactInformationCapture: string = "__PaymentProvider_ContactInformationCapture";
  public static __PaymentProvider_PaymentModel: string = "__PaymentProvider_PaymentModel";
  public static __PaymentProvider_VerificationModel: string = "__PaymentProvider_VerificationModel";
  public static __PaymentProvider_PaymentProcessingFlags: string = "__PaymentProvider_PaymentProcessingFlags";
  public static __PaymentProvider_OrderNumberType: string = "__PaymentProvider_OrderNumberType";
  public static __PaymentProvider_Encoding: string = "__PaymentProvider_Encoding";
  public static __PaymentProvider_AddressVerificationFailList: string = "__PaymentProvider_AddressVerificationFailList";
  public static __PaymentProvider_CardIdVerificationFailList: string = "__PaymentProvider_CardIdVerificationFailList";
  public static __PaymentProviderSelectionRule_PropertyName: string = "__PaymentProviderSelectionRule_PropertyName";
  public static __PaymentProvider_PaymentProviderTimeoutReportedStatus: string = "__PaymentProvider_PaymentProviderTimeoutReportedStatus";
  public static __PaymentProvider_PaymentProviderErrorReportedStatus: string = "__PaymentProvider_PaymentProviderErrorReportedStatus";
  public static __PaymentProvider_CustomTransactionProcessingFormat: string = "__PaymentProvider_CustomTransactionProcessingFormat";
  public static __PaymentProvider_CustomNotificationProcessingFormat: string = "__PaymentProvider_CustomNotificationProcessingFormat";
  public static __PaymentProviderTrigger_EventType: string = "__PaymentProviderTrigger_EventType";
  public static __PaymentTransaction_RequestType: string = "__PaymentTransaction_RequestType";
  public static __PaymentTransaction_RequestStatus: string = "__PaymentTransaction_RequestStatus";
  public static __PaymentTransaction_PaymentMethodType: string = "__PaymentTransaction_PaymentMethodType";
  public static __PaymentTransaction_PaymentMethodTypeId: string = "__PaymentTransaction_PaymentMethodTypeId";
  //public static __PaymentTransaction_PaymentMethodCardTypeId: string = "__PaymentTransaction_PaymentMethodCardTypeId";
  //public static __PaymentProviderAssumedSuccessAction_UpdatePropertyName: string = "__PaymentProviderAssumedSuccessAction_UpdatePropertyName";
  //public static __PaymentProviderAssumedSuccessAction_TriggerTypeOnSuccess: string = "__PaymentProviderAssumedSuccessAction_TriggerTypeOnSuccess";
  //public static __PaymentProviderAssumedSuccessAction_TriggerTypeOnFail: string = "__PaymentProviderAssumedSuccessAction_TriggerTypeOnFail";

  public static __SshSettings_CompressionAlgorithms: string = "__SshSettings_CompressionAlgorithms";
  public static __SshSettings_EncryptionAlgorithms: string = "__SshSettings_EncryptionAlgorithms";

  public static __TaxConfiguration_TaxableEventDetermination: string = "__TaxConfiguration_TaxableEventDetermination";
  public static __TaxConfiguration_TaxableEventSources: string = "__TaxConfiguration_TaxableEventSources";
  public static __TaxConfiguration_GeocodeSources: string = "__TaxConfiguration_GeocodeSources";
  public static __TaxConfiguration_RoamingTaxes: string = "__TaxConfiguration_RoamingTaxes";
  public static __TaxConfiguration_PaymentTransactionIndependentTaxation: string = "__TaxConfiguration_PaymentTransactionIndependentTaxation";
  public static __TaxConfiguration_LogType: string = "__TaxConfiguration_LogType";
  public static __TaxConfiguration_DefaultTaxCalculationMethod: string = "__TaxConfiguration_DefaultTaxCalculationMethod";
  public static __TaxGeocodeLookup_Type: string = "__TaxGeocodeLookup_Type";
  public static __TaxGeocodeLookup_InOutCity: string = "__TaxGeocodeLookup_InOutCity";
  public static __TaxGeocodeLookup_InOutLocal: string = "__TaxGeocodeLookup_InOutLocal";
  public static __TaxMatrix_InOutCity: string = "__TaxMatrix_InOutCity";
  public static __TaxMatrix_InOutLocal: string = "__TaxMatrix_InOutLocal";
  public static __TaxMatrix_MaximumTaxMethod: string = "__TaxMatrix_MaximumTaxMethod";
  public static __TaxMatrix_DisplayRequirement: string = "__TaxMatrix_DisplayRequirement";
  public static __TaxMatrix_DisplayMethod: string = "__TaxMatrix_DisplayMethod";
  public static __TaxMatrix_BasisType: string = "__TaxMatrix_BasisType";
  public static __TaxMatrix_TaxRateSelection: string = "__TaxMatrix_TaxRateSelection";
  public static __TaxMatrix_TaxPerUnitRateSelection: string = "__TaxMatrix_TaxPerUnitRateSelection";
  public static __TaxTransactionDetail_TaxCalculationMethod: string = "__TaxTransactionDetail_TaxCalculationMethod";
  public static __TaxTransactionDetail_ResultCode: string = "__TaxTransactionDetail_ResultCode";
  public static __VoucherLot_ActivationStatus: string = "__VoucherLot_ActivationStatus";
  public static __Voucher_Status: string = "__Voucher_Status";

  public static __ObjectStatus_StatusType: string = "__ObjectStatus_StatusType";

  public static __UsageImportLogYYYYMM_Status: string = "__UsageImportLogYYYYMM_Status";
  public static __UsageServiceIdentification_ServiceType: string = "__UsageServiceIdentification_ServiceType";
  public static __UsageServiceIdentification_UsageType: string = "__UsageServiceIdentification_UsageType";
  public static __UsageServiceIdentification_ClassificationType: string = "__UsageServiceIdentification_ClassificationType";

  public static __TelecomLocationProfile_TierMatchUsageType: string = "__TelecomLocationProfile_TierMatchUsageType";
  public static __TelecomLocationProfile_TierNumberUse: string = "__TelecomLocationProfile_TierNumberUse";
  public static __TelecomLocationProfile_LocalUseLocationGroup: string = "__TelecomLocationProfile_LocalUseLocationGroup";
  public static __TelecomLocationProfile_LocalDistanceType: string = "__TelecomLocationProfile_LocalDistanceType";
  public static __LocationProfile_VisibilityCostCenterType: string = "__LocationProfile_VisibilityCostCenterType";
  public static __LocationProfile_VisibilityListType: string = "__LocationProfile_VisibilityListType";
  public static __TelecomLocationCustom_LocationType: string = "__TelecomLocationCustom_LocationType";
  public static __TelecomLocationCustom_UsageType: string = "__TelecomLocationCustom_UsageType";
  public static __TelecomLocationCustom_DataStatus: string = "__TelecomLocationCustom_DataStatus";
  public static __TelecomLocationCustom_DistanceType: string = "__TelecomLocationCustom_DistanceType";
  public static __TelecomLocationStandard_LocationType: string = "__TelecomLocationStandard_LocationType";

  public static __Translation_Status: string = "__Translation_Status";
  public static __Translation_Scope: string = "__Translation_Scope";

  public static __UsageCostCenterRating_CostCenterType: string = "__UsageCostCenterRating_CostCenterType";

  public static ___UsageDataSource_ExternalUsageDataSourceId: string = "___UsageDataSource_ExternalUsageDataSourceId";
  public static __UsageDataSource_ServiceType: string = "__UsageDataSource_ServiceType";
  public static __UsageDataSource_UsageStorageType: string = "__UsageDataSource_UsageStorageType";
  public static __UsageDataSource_DataCaptureMethod: string = "__UsageDataSource_DataCaptureMethod";
  public static __UsageDataSource_ImportEngineOptions: string = "__UsageDataSource_ImportEngineOptions";
  public static __UsageDataSource_FormattingOptions: string = "__UsageDataSource_FormattingOptions";
  public static __UsageDataSource_MediationOptions: string = "__UsageDataSource_MediationOptions";
  public static __UsageDataSource_NumberFormat: string = "__UsageDataSource_NumberFormat";
  public static __UsageDataSource_AssociationLookupOptions: string = "__UsageDataSource_AssociationLookupOptions";
  public static __UsageDataSource_AutoAddNetworkElement: string = "__UsageDataSource_AutoAddNetworkElement";
  public static __UsageDataSource_AutoAddAccountCode: string = "__UsageDataSource_AutoAddAccountCode";
  public static __UsageDataSource_AutoAddAuthorizationCode: string = "__UsageDataSource_AutoAddAuthorizationCode";
  public static __UsageDataSource_AutoAddExtension: string = "__UsageDataSource_AutoAddExtension";
  public static __UsageDataSource_AutoAddSourceIdentifier: string = "__UsageDataSource_AutoAddSourceIdentifier";
  public static __UsageDataSource_FlagNoDirectoryEntry: string = "__UsageDataSource_FlagNoDirectoryEntry";
  public static __UsageDataSource_FlagNoCustomer1: string = "__UsageDataSource_FlagNoCustomer1";
  public static __UsageDataSource_FlagNoCustomer2: string = "__UsageDataSource_FlagNoCustomer2";
  public static __UsageDataSource_FlagCustomer12Mismatch: string = "__UsageDataSource_FlagCustomer12Mismatch";
  public static __UsageDataSource_FlagNoAgent1: string = "__UsageDataSource_FlagNoAgent1";
  public static __UsageDataSource_FlagNoAgent2: string = "__UsageDataSource_FlagNoAgent2";
  public static __UsageDataSource_FlagNoServiceProvider1: string = "__UsageDataSource_FlagNoServiceProvider1";
  public static __UsageDataSource_FlagNoServiceProvider2: string = "__UsageDataSource_FlagNoServiceProvider2";
  public static __UsageDataSource_FlagNoServiceProvider3: string = "__UsageDataSource_FlagNoServiceProvider3";
  public static __UsageDataSource_FlagNoR1RatingProfile: string = "__UsageDataSource_FlagNoR1RatingProfile";
  public static __UsageDataSource_FlagNoR2RatingProfile: string = "__UsageDataSource_FlagNoR2RatingProfile";
  public static __UsageDataSource_FlagNoA1RatingProfile: string = "__UsageDataSource_FlagNoA1RatingProfile";
  public static __UsageDataSource_FlagNoA2RatingProfile: string = "__UsageDataSource_FlagNoA2RatingProfile";
  public static __UsageDataSource_FlagNoW1RatingProfile: string = "__UsageDataSource_FlagNoW1RatingProfile";
  public static __UsageDataSource_FlagNoW2RatingProfile: string = "__UsageDataSource_FlagNoW2RatingProfile";
  public static __UsageDataSource_FlagNoW3RatingProfile: string = "__UsageDataSource_FlagNoW3RatingProfile";
  public static __UsageDataSource_FlagNoT1RatingProfile: string = "__UsageDataSource_FlagNoT1RatingProfile";
  public static __UsageDataFeed_MediationStatus: string = "__UsageDataFeed_MediationStatus";
  public static __UsageDataFeed_Status: string = "__UsageDataFeed_Status";
  public static __UsageDataFeed_UsageRecordType: string = "__UsageDataFeed_UsageRecordType";
  public static __UsageDataFeed_ClassificationType: string = "__UsageDataFeed_ClassificationType";
  public static __UsageDataFeed_ServiceType: string = "__UsageDataFeed_ServiceType";
  public static __UsageDataFeed_ContactType: string = "__UsageDataFeed_ContactType";
  public static __UsageDataFeed_Direction: string = "__UsageDataFeed_Direction";
  public static __UsageDataFeed_TargetType: string = "__UsageDataFeed_TargetType";
  public static __UsageDataFeed_SourceType: string = "__UsageDataFeed_SourceType";

  public static ___NetworkElement_ExternalNetworkElementId: string = "___NetworkElement_ExternalNetworkElementId";

  public static __Visibility_VisibilityType: string = "__Visibility_VisibilityType";

  public static __LogYYYYMM_LogType: string = "__LogYYYYMM_LogType";
  public static __WebhookEventYYYYMM_Status: string = "__WebhookEventYYYYMM_Status";

  public static __Job_LogType: string = "__Job_LogType";
  public static __Job_ScheduleType: string = "__Job_ScheduleType";
  public static __Job_TriggeredByJobWhen: string = "__Job_TriggeredByJobWhen";
  public static __Job_Scope: string = "__Job_Scope";
  public static __Job_Status: string = "__Job_Status";

  public static __Job_NotificationNoProgressDays1: string = "__Job_NotificationNoProgressDays1";
  public static __Job_NotificationNoProgressDays2: string = "__Job_NotificationNoProgressDays2";
  public static __Job_NotificationNoProgressDays3: string = "__Job_NotificationNoProgressDays3";
  public static __Job_NotificationNoProgressDays4: string = "__Job_NotificationNoProgressDays4";

  public static __Package_QuantityCalculationFlag: string = "__Package_QuantityCalculationFlag";
  public static __Package_RateAdjustmentFlag: string = "__Package_RateAdjustmentFlag";
  public static __Package_PackageConversionFlag: string = "__Package_PackageConversionFlag";
  public static __Package_AgentTrackingFlag: string = "__Package_AgentTrackingFlag";
  public static __Package_ValidBillingPeriodFlag: string = "__Package_ValidBillingPeriodFlag";
  public static __Package_TaxCalculationMethod: string = "__Package_TaxCalculationMethod";
  public static __Package_VisibilityCostCenterType: string = "__Package_VisibilityCostCenterType";
  public static __Package_VisibilityAccountType: string = "__Package_VisibilityAccountType";
  public static __Package_VisibilityAccountClass: string = "__Package_VisibilityAccountClass";
  public static __Package_VisibilityMarketType: string = "__Package_VisibilityMarketType";
  public static __Package_VisibilityServiceType: string = "__Package_VisibilityServiceType";
  public static __Package_VisibilityBillingCycle: string = "__Package_VisibilityBillingCycle";
  public static __Package_ContractCanConvert: string = "__Package_ContractCanConvert";

  public static __PackageItem_ItemTrigger: string = "__PackageItem_ItemTrigger";
  public static __PackageRateAdjustment_UnitType: string = "__PackageRateAdjustment_UnitType";
  public static __PackageRateAdjustment_TypeList: string = "__PackageRateAdjustment_TypeList";
  public static __PackageInventory_ActivateActionFlag: string = "__PackageInventory_ActivateActionFlag";
  public static __PackageInventory_CloseActionFlag: string = "__PackageInventory_CloseActionFlag";
  public static __PackageAssociation_AssociationType: string = "__PackageAssociation_AssociationType";
  public static __PackageAssociation_ActivateActionFlag: string = "__PackageAssociation_ActivateActionFlag";
  public static __PackageAssociation_CloseActionFlag: string = "__PackageAssociation_CloseActionFlag";

  public static __PackageOccurrence_Status: string = "__PackageOccurrence_Status";
  public static __PackageOccurrence_ActivationFlag: string = "__PackageOccurrence_ActivationFlag";
  public static __PackageOccurrence_CancellationFlag: string = "__PackageOccurrence_CancellationFlag";

  public static __PackageOccurrenceItem_ItemTrigger: string = "__PackageOccurrenceItem_ItemTrigger";
  public static __PackageOccurrenceRateAdjustment_TypeList: string = "__PackageOccurrenceRateAdjustment_TypeList";
  public static __PackageOccurrenceRateAdjustment_UnitType: string = "__PackageOccurrenceRateAdjustment_UnitType";

  // A triple leading underscore is for pick lists built using distinct existing values for a table and column for things like type-ahead support
  public static ___Tag_Tag: string = "___Tag_Tag";
  public static ___Inventory_Condition: string = "___Inventory_Condition";
  public static ___Inventory_MaintenanceType: string = "___Inventory_MaintenanceType";
  public static ___InventoryType_Class: string = "___InventoryType_Class";
  public static ___InventoryType_Category: string = "___InventoryType_Category";
  public static ___InventoryType_Condition: string = "___InventoryType_Condition";
  public static ___InventoryType_MaintenanceType: string = "___InventoryType_MaintenanceType";
  public static ___InventoryTypeVersion_Version: string = "___InventoryTypeVersion_Version";
  public static ___Switch_Switch_Id: string = "___Switch_Switch_Id";
  public static ___Asset_AssetScope: string = "___Asset_AssetScope";
  public static ___Package_PackageCode: string = "___Package_PackageCode";
  public static ___Package_PackageType: string = "___Package_PackageType";
  public static ___Package_PackageClass: string = "___Package_PackageClass";

}

export class SystemPickList {
  public static ReportCompilerAttachmentClassification: string = "RC.Attachment.Classification";
  public static ReportCompilerStructuralCause: string = "RC.Structural.Cause";
  public static ReportCompilerStructuralExteriorCladding: string = "RC.Structural.ExteriorCladding";
  public static ReportCompilerStructuralExteriorDamage: string = "RC.Structural.ExteriorDamage";
  public static ReportCompilerStructuralExteriorElement: string = "RC.Structural.ExteriorElement";
  public static ReportCompilerStructuralExteriorRoofing: string = "RC.Structural.ExteriorRoofing";
  public static ReportCompilerStructuralExteriorSubject: string = "RC.Structural.ExteriorSubject";
  public static ReportCompilerStructuralInteriorCladding: string = "RC.Structural.InteriorCladding";
  public static ReportCompilerStructuralInteriorDamage: string = "RC.Structural.InteriorDamage";
  public static ReportCompilerStructuralInteriorElement: string = "RC.Structural.InteriorElement";
  public static ReportCompilerStructuralInteriorFloorCovering: string = "RC.Structural.InteriorFloorCovering";
  public static ReportCompilerStructuralInteriorSubject: string = "RC.Structural.InteriorSubject";
  public static ReportCompilerStructuralPropertyType: string = "RC.Structural.PropertyType";
  public static ReportCompilerStructuralReportFrontDirection: string = "RC.Structural.ReportFrontDirection";
  public static ReportCompilerStructuralTrueFrontDirection: string = "RC.Structural.TrueFrontDirection";
  public static ReportCompilerStructuralStories: string = "RC.Structural.Stories";
  public static ReportCompilerStructuralOverallFraming: string = "RC.Structural.OverallFraming";
  public static ReportCompilerStructuralWallFraming: string = "RC.Structural.WallFraming";
  public static ReportCompilerStructuralRoofFraming: string = "RC.Structural.RoofFraming";
  public static ReportCompilerStructuralFloorFraming: string = "RC.Structural.FloorFraming";
  public static ReportCompilerStructuralOverallFoundation: string = "RC.Structural.OverallFoundation";
  public static ReportCompilerStructuralFoundation: string = "RC.Structural.Foundation";
}

export class Modules {

  public static RoleBasedSecurity = "role-based-security";
  public static UserGroups = "user-groups";
  public static CRM = "crm";
  public static API = "api";
  public static Billing = "billing";
  public static Inventory = "inventory";
  public static Payment = "payment";
  public static Tax = "tax";
  public static Usage = "usage";
  public static Telecom = "telecom";
  public static Survey = "survey";
  public static Case = "case";
  public static CustomerPortal = "customer-portal";

  public static ReportCompilerLibraryPersonal = "rc-library-personal";
  public static ReportCompilerLibraryIndustry = "rc-library-industry";
  public static ReportCompilerLibraryOrganization = "rc-library-organization";
  public static ReportCompilerLibraryInsights = "rc-library-insights";
  public static ReportCompilerLibraryExport = "rc-library-export";

  public static ReportCompilerManagementInsights = "rc-management-insights";
  public static ReportCompilerReportParser = "rc-report-parser";
  public static ReportCompilerReportFinalPdf = "rc-report-final-pdf";
  public static ReportCompilerWorkflow = "rc-workflow";

}


export class OptionsList {
  public static NotDeliveredType: string = "NotDeliveredType";
  public static NotificationContactMethod: string = "NotificationContactMethod";
  public static NotificationRecipientType: string = "NotificationRecipientType";
  public static BroadcastGroup: string = "BroadcastGroup";
}

export class AccessArea {

  // Special
  public static None: string = "";

  // Tables
  public static AccountsPayableAdjustment: string = "AccountsPayableAdjustment";
  public static AccountsPayableAdjustmentExclusions: string = "AccountsPayableAdjustmentExclusions";
  public static AccountsPayableAdjustmentWaivers: string = "AccountsPayableAdjustmentWaivers";
  public static AccountsPayableBillingAccount: string = "AccountsPayableBillingAccount";
  public static AccountsPayableInvoice: string = "AccountsPayableInvoice";
  public static AccountsPayablePayment: string = "AccountsPayablePayment";
  public static AccountsReceivableAdjustment: string = "AccountsReceivableAdjustment";
  public static AccountsReceivableAdjustmentExclusions: string = "AccountsReceivableAdjustmentExclusions";
  public static AccountsReceivableAdjustmentWaivers: string = "AccountsReceivableAdjustmentWaivers";
  public static AccountsReceivableBillingAccount: string = "AccountsReceivableBillingAccount";
  public static AccountsReceivableInvoice: string = "AccountsReceivableInvoice";
  public static AccountsReceivablePayment: string = "AccountsReceivablePayment";
  public static ActionLink: string = "ActionLink";
  public static ACTIVITY_SUMMARY_BASIC: string = "ACTIVITY_SUMMARY_BASIC";
  public static ACTIVITY_SUMMARY_HOURLY: string = "ACTIVITY_SUMMARY_HOURLY";
  public static ACTIVITY_SUMMARY_INFORMATION: string = "ACTIVITY_SUMMARY_INFORMATION";
  public static Agent: string = "Agent";
  public static AlarmRule: string = "AlarmRule";
  public static AlarmRuleNotification: string = "AlarmRuleNotification";
  public static Alias: string = "Alias";
  public static ApiAccess: string = "ApiAccess";
  public static ApiAccessClient: string = "ApiAccessClient";
  public static ApiKeyContact: string = "ApiKeyContact";
  public static Asset: string = "Asset";
  public static AssetAccessLog: string = "AssetAccessLog";
  public static AssetLicense: string = "AssetLicense";
  public static AssetSelection: string = "AssetSelection";
  public static Association: string = "Association";
  public static AssociationArchive: string = "AssociationArchive";
  public static Attribute: string = "Attribute";
  public static AttributeConfiguration: string = "AttributeConfiguration";
  public static AttributeSet: string = "AttributeSet";
  public static AuditTrail: string = "AuditTrail";
  public static BillingAccount: string = "BillingAccount";
  public static BillingEvent: string = "BillingEvent";
  public static BillingProfile: string = "BillingProfile";
  public static BillingReport: string = "BillingReport";
  public static BillingReportProfile: string = "BillingReportProfile";
  public static BillingTransaction: string = "BillingTransaction";
  public static BillingTransactionBatch: string = "BillingTransactionBatch";
  public static BillingTransactionCommissionDetail: string = "BillingTransactionCommissionDetail";
  public static BillingTransactionDetail: string = "BillingTransactionDetail";
  public static BillingTransactionPaymentDetail: string = "BillingTransactionPaymentDetail";
  public static CachePackageSignup: string = "CachePackageSignup";
  public static CachePackageSignupArchive: string = "CachePackageSignupArchive";
  public static CacheSummaryConfiguration: string = "CacheSummaryConfiguration";
  public static CacheSummaryData: string = "CacheSummaryData";
  public static CaseAccess: string = "CaseAccess";
  public static CaseCorrespondence: string = "CaseCorrespondence";
  public static CaseCorrespondenceRecipient: string = "CaseCorrespondenceRecipient";
  public static Cases: string = "Cases";
  public static CaseTemplate: string = "CaseTemplate";
  public static Certificate: string = "Certificate";
  public static CollectionAgency: string = "CollectionAgency";
  public static CONTACT: string = "CONTACT";
  public static ContactAccess: string = "ContactAccess";
  public static ContactConsent: string = "ContactConsent";
  public static ContactExternalAuthentication: string = "ContactExternalAuthentication";
  public static ContactGroupMembership: string = "ContactGroupMembership";
  public static ContactRole: string = "ContactRole";
  public static ContactWorkSchedule: string = "ContactWorkSchedule";
  public static ContactWorkScheduleException: string = "ContactWorkScheduleException";
  public static Currency: string = "Currency";
  public static Customer: string = "Customer";
  public static DataImportDefinition: string = "DataImportDefinition";
  public static DataPartitionSchema: string = "DataPartitionSchema";
  public static DataSource: string = "DataSource";
  public static DataTableSupport: string = "DataTableSupport";
  public static Directory: string = "Directory";
  public static Favorite: string = "Favorite";
  public static Feedback: string = "Feedback";
  public static FileServer: string = "FileServer";
  public static Filter: string = "Filter";
  public static Form: string = "Form";
  public static FormControl: string = "FormControl";
  public static FormControlGroup: string = "FormControlGroup";
  public static FreeFormTextParser: string = "FreeFormTextParser";
  public static Group: string = "Group";
  public static HelpLink: string = "HelpLink";
  public static IndustryLibraryTypeInformation: string = "IndustryLibraryTypeInformation";
  public static Inventory: string = "Inventory";
  public static InventoryEvent: string = "InventoryEvent";
  public static InventoryHistory: string = "InventoryHistory";
  public static InventoryLocation: string = "InventoryLocation";
  public static InventoryQuantityType: string = "InventoryQuantityType";
  public static InventoryType: string = "InventoryType";
  public static InventoryTypeVersion: string = "InventoryTypeVersion";
  public static InvoiceMessage: string = "InvoiceMessage";
  public static Item: string = "Item";
  public static ItemList: string = "ItemList";
  public static ItemRate: string = "ItemRate";
  public static Job: string = "Job";
  public static LibraryGroupConfiguration: string = "LibraryGroupConfiguration";
  public static LibraryIndustryDocument: string = "LibraryIndustryDocument";
  public static LibraryIndustryText: string = "LibraryIndustryText";
  public static LibraryOrganizationDocument: string = "LibraryOrganizationDocument";
  public static LibraryOrganizationText: string = "LibraryOrganizationText";
  public static LibraryPersonalDocument: string = "LibraryPersonalDocument";
  public static LibraryPersonalText: string = "LibraryPersonalText";
  public static LifeCycle: string = "LifeCycle";
  public static Location: string = "Location";
  public static Log: string = "Log";
  public static MailAddressAction: string = "MailAddressAction";
  public static MailServer: string = "MailServer";
  public static MarketingContact: string = "MarketingContact";
  public static MessageExchange: string = "MessageExchange";
  public static Mutex: string = "Mutex";
  public static NetworkElement: string = "NetworkElement";
  public static NetworkPort: string = "NetworkPort";
  public static NetworkPortGroup: string = "NetworkPortGroup";
  public static Note: string = "Note";
  public static Notification: string = "Notification";
  public static NotificationContact: string = "NotificationContact";
  public static NotificationEvent: string = "NotificationEvent";
  public static NotificationGroup: string = "NotificationGroup";
  public static NotificationGroupDetail: string = "NotificationGroupDetail";
  public static NotificationMessage: string = "NotificationMessage";
  public static NotificationOptOut: string = "NotificationOptOut";
  public static ObjectStatus: string = "ObjectStatus";
  public static Package: string = "Package";
  public static PackageAssociation: string = "PackageAssociation";
  public static PackageInventory: string = "PackageInventory";
  public static PackageItem: string = "PackageItem";
  public static PackageOccurrence: string = "PackageOccurrence";
  public static PackageOccurrenceArchive: string = "PackageOccurrenceArchive";
  public static PackageOccurrenceItem: string = "PackageOccurrenceItem";
  public static PackageOccurrenceItemArchive: string = "PackageOccurrenceItemArchive";
  public static PackageOccurrenceRateAdjustment: string = "PackageOccurrenceRateAdjustment";
  public static PackageOccurrenceRateAdjustmentArchive: string = "PackageOccurrenceRateAdjustmentArchive";
  public static PackageRateAdjustment: string = "PackageRateAdjustment";
  public static Partition: string = "Partition";
  public static PartitionDomain: string = "PartitionDomain";
  public static PartitionRoute: string = "PartitionRoute";
  public static PartitionService: string = "PartitionService";
  public static PaymentMethod: string = "PaymentMethod";
  public static PaymentMethodCardType: string = "PaymentMethodCardType";
  public static PaymentProvider: string = "PaymentProvider";
  public static PaymentProviderSelectionRule: string = "PaymentProviderSelectionRule";
  public static PaymentProviderSupportedCardType: string = "PaymentProviderSupportedCardType";
  public static PaymentProviderTrigger: string = "PaymentProviderTrigger";
  public static PaymentTransaction: string = "PaymentTransaction";
  public static PickList: string = "PickList";
  public static PickListCategory: string = "PickListCategory";
  public static PickListValue: string = "PickListValue";
  public static Process: string = "Process";
  public static ProcessStatus: string = "ProcessStatus";
  public static ProcessStatusDetail: string = "ProcessStatusDetail";
  public static ProcessStep: string = "ProcessStep";
  public static ProcessTemplate: string = "ProcessTemplate";
  public static ProcessTemplateStep: string = "ProcessTemplateStep";
  public static Prospect: string = "Prospect";
  public static ProxyServer: string = "ProxyServer";
  public static PurchaseCheckout: string = "PurchaseCheckout";
  public static Query: string = "Query";
  public static QueryEvent: string = "QueryEvent";
  public static QueryEventFile: string = "QueryEventFile";
  public static QueryEventFileDelivery: string = "QueryEventFileDelivery";
  public static RatingGroup: string = "RatingGroup";
  public static RatingHoliday: string = "RatingHoliday";
  public static RatingProfile: string = "RatingProfile";
  public static RatingRate: string = "RatingRate";
  public static RatingStep: string = "RatingStep";
  public static RatingSurchargeDiscount: string = "RatingSurchargeDiscount";
  public static RatingZone: string = "RatingZone";
  public static Reference: string = "Reference";
  public static ReportTemplate: string = "ReportTemplate";
  public static RestrictionCheck: string = "RestrictionCheck";
  public static RestrictionCheckArchive: string = "RestrictionCheckArchive";
  public static RestrictionRule: string = "RestrictionRule";
  public static RestrictionRuleArchive: string = "RestrictionRuleArchive";
  public static RetailLocation: string = "RetailLocation";
  public static Role: string = "Role";
  public static RoleDetail: string = "RoleDetail";
  public static SalesOpportunity: string = "SalesOpportunity";
  public static SearchConfiguration: string = "SearchConfiguration";
  public static SecurityPolicy: string = "SecurityPolicy";
  public static ServerResource: string = "ServerResource";
  public static ServerResourceAlarmRule: string = "ServerResourceAlarmRule";
  public static ServiceProvider: string = "ServiceProvider";
  public static Setting: string = "Setting";
  public static SETUP_DATA: string = "SETUP_DATA";
  public static SR: string = "SR";
  public static SubscriptionConfig: string = "SubscriptionConfig";
  public static SubscriptionOption: string = "SubscriptionOption";
  public static Survey: string = "Survey";
  public static SurveyAnswer: string = "SurveyAnswer";
  public static SurveyAnswerMatrix: string = "SurveyAnswerMatrix";
  public static SurveyPage: string = "SurveyPage";
  public static SurveyQuestion: string = "SurveyQuestion";
  public static SurveyResponse: string = "SurveyResponse";
  public static SurveyResponseDetail: string = "SurveyResponseDetail";
  public static SurveyResponseGroup: string = "SurveyResponseGroup";
  public static SurveyRevisionHistory: string = "SurveyRevisionHistory";
  public static SurveyScore: string = "SurveyScore";
  public static SyncArticle: string = "SyncArticle";
  public static SyncArticleDetail: string = "SyncArticleDetail";
  public static SyncArticleSubscription: string = "SyncArticleSubscription";
  public static SyncDataStore: string = "SyncDataStore";
  public static SyncPublisherArticleType: string = "SyncPublisherArticleType";
  public static SyncSubscription: string = "SyncSubscription";
  public static TABLE_FILTER: string = "TABLE_FILTER";
  public static TABLE_FILTER_DETAIL: string = "TABLE_FILTER_DETAIL";
  public static TableConfiguration: string = "TableConfiguration";
  public static Tag: string = "Tag";
  public static Task: string = "Task";
  public static TaskList: string = "TaskList";
  public static TaskListTemplate: string = "TaskListTemplate";
  public static TaskSubtask: string = "TaskSubtask";
  public static TaskSubtaskTemplate: string = "TaskSubtaskTemplate";
  public static TaskTemplate: string = "TaskTemplate";
  public static TaxAuthority: string = "TaxAuthority";
  public static TaxConfiguration: string = "TaxConfiguration";
  public static TaxGeocode: string = "TaxGeocode";
  public static TaxGeocodeLookup: string = "TaxGeocodeLookup";
  public static TaxJurisdiction: string = "TaxJurisdiction";
  public static TaxMatrix: string = "TaxMatrix";
  public static TaxTransactionDetail: string = "TaxTransactionDetail";
  public static TelecomLocationCustom: string = "TelecomLocationCustom";
  public static TelecomLocationGroupLink: string = "TelecomLocationGroupLink";
  public static TelecomLocationGroupList: string = "TelecomLocationGroupList";
  public static TelecomLocationProfile: string = "TelecomLocationProfile";
  public static TelecomLocationStandard: string = "TelecomLocationStandard";
  public static TelecomNumberingPlan: string = "TelecomNumberingPlan";
  public static TemplateDocument: string = "TemplateDocument";
  public static TemplateTypeConfiguration: string = "TemplateTypeConfiguration";
  public static TimeLog: string = "TimeLog";
  public static TimeLogArchive: string = "TimeLogArchive";
  public static TimeZone: string = "TimeZone";
  public static Translation: string = "Translation";
  public static Trigger: string = "Trigger";
  public static UsageCostCenterRating: string = "UsageCostCenterRating";
  public static UsageDataFeed: string = "UsageDataFeed";
  public static UsageDataSource: string = "UsageDataSource";
  public static UsageImportLog: string = "UsageImportLog";
  public static UsageImportSerialNumber: string = "UsageImportSerialNumber";
  public static UsageServiceIdentification: string = "UsageServiceIdentification";
  public static Vendor: string = "Vendor";
  public static Visibility: string = "Visibility";
  public static VisibilityArchive: string = "VisibilityArchive";
  public static Voucher: string = "Voucher";
  public static VoucherArchive: string = "VoucherArchive";
  public static VoucherBatch: string = "VoucherBatch";
  public static VoucherBatchArchive: string = "VoucherBatchArchive";
  public static VoucherLot: string = "VoucherLot";
  public static VoucherLotArchive: string = "VoucherLotArchive";
  public static Warehouse: string = "Warehouse";
  public static Watch: string = "Watch";
  public static WatchEvent: string = "WatchEvent";
  public static Webhook: string = "Webhook";
  public static WebhookEvent: string = "WebhookEvent";

  // Permission Areas
  public static AssetApproval: string = "AssetApproval";
  public static AssetFeedbackReview: string = "AssetFeedbackReview";
  public static AssetReview: string = "AssetReview";
  public static BillBatchApproval: string = "BillBatchApproval";
  public static CaseFeedbackReview: string = "CaseFeedbackReview";
  public static CaseReview: string = "CaseReview";
  public static CaseReviewResult: string = "CaseReviewResult";
  public static CaseManagement: string = "CaseManagement";
  public static CheckIn: string = "CheckIn";
  public static CheckOut: string = "CheckOut";
  public static Everything: string = "Everything";
  public static FilterApproval: string = "FilterApproval";
  public static InvoiceBatchApproval: string = "InvoiceBatchApproval";
  public static LibraryIndustryApproval: string = "LibraryIndustryApproval";
  public static LibraryInsights: string = "LibraryInsights";
  public static LibraryOrganizationApproval: string = "LibraryOrganizationApproval";
  public static PortalSetting: string = "PortalSetting";
  public static QueryApproval: string = "QueryApproval";
  public static RefundBatchApproval: string = "RefundBatchApproval";
  public static ReportParser: string = "ReportParser";
  public static Reports: string = "Reports";
  public static SurveyResponseReview: string = "SurveyResponseReview";
  public static SurveyRevisionApproval: string = "SurveyRevisionApproval";
  public static TranslationApproval: string = "TranslationApproval";
  public static UdrLayout: string = "UdrLayout";

}

export class Permission {
  public static ReadSingle: string = "S";
  public static Read: string = "R";
  public static Add: string = "A";
  public static Edit: string = "E";
  public static Delete: string = "D";
  public static Output: string = "O";
  public static Execute: string = "X";
  public static Full: string = "F";
}

export class RowsToReturn {
  public static Default: number = 10;
  public static Max: number = 1000;
  public static All: number = 2147483647;
}

export class FavoriteRank {
  public static Love: number = 2000000002;
  public static Like: number = 2000000001;
  public static Neutral: number = 0;
  public static Dislike: number = -2000000001;
  public static Block: number = -2000000002;
}

export class Dashboard {
  public static PageIdPrefix: string = "dashboard-page-";
  public static WidgetIdPrefix: string = "dashboard-page-widget-";
  public static WidgetHeaderHeight: number = 40;
  public static WidgetZIndex: number = 2;
  public static BroadcastWidgetPropertiesChange: string = "dashboard-widget-properties-change";
  public static BroadcastWidgetFirstViewed: string = "dashboard-widget-first-viewed";
  public static BroadcastWidgetVisible: string = "dashboard-widget-visible";
  public static BroadcastWidgetHidden: string = "dashboard-widget-hidden";
  public static BroadcastWidgetRefresh: string = "dashboard-widget-refresh";
  // When refreshing all widgets of given type
  public static BroadcastWidgetTypeRefresh: string = "dashboard-widget-type-refresh";
  public static BroadcastWidgetAction: string = "dashboard-widget-action";
  public static BroadcastPageSizeChange: string = "gridster-resized";
  public static BroadcastWidgetSizeChange: string = "gridster-item-transition-end";
}

export class Color {
  public Color: string;
  public Hex: string;
}

export class App {

  /**
  List of colors to use in charts in preferred order.  Can be assigned by index to this array.
  Starts with preferred colors, goes to less preferred colors, and finally bad colors.
  */
  public static ChartColors: Color[] = [
    { Color: "Red", Hex: "#FF0000" },
    { Color: "Blue", Hex: "#0000FF" },
    { Color: "Yellow", Hex: "#FFFF00" },
    { Color: "Purple", Hex: "#800080" },
    { Color: "Orange", Hex: "#FFA500" },
    { Color: "Green", Hex: "#008000" },
    { Color: "Brown", Hex: "#A52A2A" },
    { Color: "LightBlue", Hex: "#ADD8E6" },
    { Color: "Gray", Hex: "#808080" },
    { Color: "Lime", Hex: "#00FF00" },
    { Color: "Magenta", Hex: "#FF00FF" },
    { Color: "Cyan", Hex: "#00FFFF" },


    { Color: "BlueViolet", Hex: "#8A2BE2" },
    { Color: "BurlyWood", Hex: "#DEB887" },
    { Color: "CadetBlue", Hex: "#5F9EA0" },
    { Color: "Chartreuse", Hex: "#7FFF00" },
    { Color: "Chocolate", Hex: "#D2691E" },
    { Color: "Coral", Hex: "#FF7F50" },
    { Color: "CornflowerBlue", Hex: "#6495ED" },
    { Color: "Crimson", Hex: "#DC143C" },
    { Color: "DarkBlue", Hex: "#00008B" },
    { Color: "Aqua", Hex: "#00FFFF" },
    { Color: "DarkCyan", Hex: "#008B8B" },
    { Color: "DarkGoldenRod", Hex: "#B8860B" },
    { Color: "DarkGray", Hex: "#A9A9A9" },
    { Color: "DarkGreen", Hex: "#006400" },
    { Color: "DarkKhaki", Hex: "#BDB76B" },
    { Color: "DarkMagenta", Hex: "#8B008B" },
    { Color: "DarkOliveGreen", Hex: "#556B2F" },
    { Color: "DarkOrange", Hex: "#FF8C00" },
    { Color: "DarkOrchid", Hex: "#9932CC" },
    { Color: "DarkRed", Hex: "#8B0000" },
    { Color: "DarkSalmon", Hex: "#E9967A" },
    { Color: "DarkSeaGreen", Hex: "#8FBC8F" },
    { Color: "DarkSlateBlue", Hex: "#483D8B" },
    { Color: "DarkSlateGray", Hex: "#2F4F4F" },
    { Color: "DarkTurquoise", Hex: "#00CED1" },
    { Color: "DarkViolet", Hex: "#9400D3" },
    { Color: "DeepPink", Hex: "#FF1493" },
    { Color: "DeepSkyBlue", Hex: "#00BFFF" },
    { Color: "Aquamarine", Hex: "#7FFFD4" },
    { Color: "DimGray", Hex: "#696969" },
    { Color: "DodgerBlue", Hex: "#1E90FF" },
    { Color: "FireBrick", Hex: "#B22222" },
    { Color: "ForestGreen", Hex: "#228B22" },
    { Color: "Fuchsia", Hex: "#FF00FF" },
    { Color: "Gold", Hex: "#FFD700" },
    { Color: "GoldenRod", Hex: "#DAA520" },
    { Color: "GreenYellow", Hex: "#ADFF2F" },
    { Color: "HotPink", Hex: "#FF69B4" },
    { Color: "IndianRed ", Hex: "#CD5C5C" },
    { Color: "Indigo ", Hex: "#4B0082" },
    { Color: "Khaki", Hex: "#F0E68C" },
    { Color: "LawnGreen", Hex: "#7CFC00" },
    { Color: "LemonChiffon", Hex: "#FFFACD" },
    { Color: "LightCoral", Hex: "#F08080" },
    { Color: "LightCyan", Hex: "#E0FFFF" },
    { Color: "LightGoldenRodYellow", Hex: "#FAFAD2" },
    { Color: "LightGray", Hex: "#D3D3D3" },
    { Color: "LightGreen", Hex: "#90EE90" },
    { Color: "LightPink", Hex: "#FFB6C1" },
    { Color: "LightSalmon", Hex: "#FFA07A" },
    { Color: "LightSeaGreen", Hex: "#20B2AA" },
    { Color: "LightSkyBlue", Hex: "#87CEFA" },
    { Color: "LightSlateGray", Hex: "#778899" },
    { Color: "LightSteelBlue", Hex: "#B0C4DE" },
    { Color: "LimeGreen", Hex: "#32CD32" },
    { Color: "Linen", Hex: "#FAF0E6" },
    { Color: "Maroon", Hex: "#800000" },
    { Color: "MediumAquaMarine", Hex: "#66CDAA" },
    { Color: "MediumBlue", Hex: "#0000CD" },
    { Color: "MediumOrchid", Hex: "#BA55D3" },
    { Color: "MediumPurple", Hex: "#9370DB" },
    { Color: "MediumSeaGreen", Hex: "#3CB371" },
    { Color: "MediumSlateBlue", Hex: "#7B68EE" },
    { Color: "MediumSpringGreen", Hex: "#00FA9A" },
    { Color: "MediumTurquoise", Hex: "#48D1CC" },
    { Color: "MediumVioletRed", Hex: "#C71585" },
    { Color: "MidnightBlue", Hex: "#191970" },
    { Color: "NavajoWhite", Hex: "#FFDEAD" },
    { Color: "Navy", Hex: "#000080" },
    { Color: "Olive", Hex: "#808000" },
    { Color: "OliveDrab", Hex: "#6B8E23" },
    { Color: "OrangeRed", Hex: "#FF4500" },
    { Color: "Orchid", Hex: "#DA70D6" },
    { Color: "PaleGoldenRod", Hex: "#EEE8AA" },
    { Color: "PaleGreen", Hex: "#98FB98" },
    { Color: "PaleTurquoise", Hex: "#AFEEEE" },
    { Color: "PaleVioletRed", Hex: "#DB7093" },
    { Color: "PapayaWhip", Hex: "#FFEFD5" },
    { Color: "PeachPuff", Hex: "#FFDAB9" },
    { Color: "Peru", Hex: "#CD853F" },
    { Color: "Pink", Hex: "#FFC0CB" },
    { Color: "Plum", Hex: "#DDA0DD" },
    { Color: "PowderBlue", Hex: "#B0E0E6" },
    { Color: "RebeccaPurple", Hex: "#663399" },
    { Color: "RosyBrown", Hex: "#BC8F8F" },
    { Color: "RoyalBlue", Hex: "#4169E1" },
    { Color: "SaddleBrown", Hex: "#8B4513" },
    { Color: "Salmon", Hex: "#FA8072" },
    { Color: "SandyBrown", Hex: "#F4A460" },
    { Color: "SeaGreen", Hex: "#2E8B57" },
    { Color: "Sienna", Hex: "#A0522D" },
    { Color: "Silver", Hex: "#C0C0C0" },
    { Color: "SkyBlue", Hex: "#87CEEB" },
    { Color: "SlateBlue", Hex: "#6A5ACD" },
    { Color: "SlateGray", Hex: "#708090" },
    { Color: "SpringGreen", Hex: "#00FF7F" },
    { Color: "SteelBlue", Hex: "#4682B4" },
    { Color: "Tan", Hex: "#D2B48C" },
    { Color: "Teal", Hex: "#008080" },
    { Color: "Thistle", Hex: "#D8BFD8" },
    { Color: "Tomato", Hex: "#FF6347" },
    { Color: "Turquoise", Hex: "#40E0D0" },
    { Color: "Violet", Hex: "#EE82EE" },
    { Color: "Wheat", Hex: "#F5DEB3" },
    { Color: "YellowGreen", Hex: "#9ACD32" },


    { Color: "AliceBlue", Hex: "#F0F8FF" },
    { Color: "AntiqueWhite", Hex: "#FAEBD7" },
    { Color: "Azure", Hex: "#F0FFFF" },
    { Color: "Beige", Hex: "#F5F5DC" },
    { Color: "Bisque", Hex: "#FFE4C4" },
    { Color: "Black", Hex: "#000000" },
    { Color: "BlanchedAlmond", Hex: "#FFEBCD" },
    { Color: "Cornsilk", Hex: "#FFF8DC" },
    { Color: "FloralWhite", Hex: "#FFFAF0" },
    { Color: "Gainsboro", Hex: "#DCDCDC" },
    { Color: "GhostWhite", Hex: "#F8F8FF" },
    { Color: "HoneyDew", Hex: "#F0FFF0" },
    { Color: "Ivory", Hex: "#FFFFF0" },
    { Color: "Lavender", Hex: "#E6E6FA" },
    { Color: "LavenderBlush", Hex: "#FFF0F5" },
    { Color: "LightYellow", Hex: "#FFFFE0" },
    { Color: "MintCream", Hex: "#F5FFFA" },
    { Color: "MistyRose", Hex: "#FFE4E1" },
    { Color: "Moccasin", Hex: "#FFE4B5" },
    { Color: "OldLace", Hex: "#FDF5E6" },
    { Color: "SeaShell", Hex: "#FFF5EE" },
    { Color: "Snow", Hex: "#FFFAFA" },
    { Color: "White", Hex: "#FFFFFF" },
    { Color: "WhiteSmoke", Hex: "#F5F5F5" },


  ];

  /**
  List of colors to use in charts in preferred order.  Can be assigned by index to this array.
  Starts with preferred colors, goes to less preferred colors, and finally bad colors.
  */
  public static ChartColorList: string[] = [
    "Red",
    "Blue",
    "Purple",
    "Yellow",
    "Orange",
    "Green",
    "Brown",
    "LightBlue",
    "Gray",
    "Lime",
    "Magenta",
    "Cyan",

    "Aqua",
    "Aquamarine",
    "BlueViolet",
    "BurlyWood",
    "CadetBlue",
    "Chartreuse",
    "Chocolate",
    "Coral",
    "CornflowerBlue",
    "Crimson",
    "DarkBlue",
    "DarkCyan",
    "DarkGoldenRod",
    "DarkGray",
    "DarkGreen",
    "DarkKhaki",
    "DarkMagenta",
    "DarkOliveGreen",
    "DarkOrange",
    "DarkOrchid",
    "DarkRed",
    "DarkSalmon",
    "DarkSeaGreen",
    "DarkSlateBlue",
    "DarkSlateGray",
    "DarkTurquoise",
    "DarkViolet",
    "DeepPink",
    "DeepSkyBlue",
    "DimGray",
    "DodgerBlue",
    "FireBrick",
    "ForestGreen",
    "Fuchsia",
    "Gold",
    "GoldenRod",
    "GreenYellow",
    "HotPink",
    "IndianRed ",
    "Indigo ",
    "Khaki",
    "LawnGreen",
    "LemonChiffon",
    "LightCoral",
    "LightCyan",
    "LightGoldenRodYellow",
    "LightGray",
    "LightGreen",
    "LightPink",
    "LightSalmon",
    "LightSeaGreen",
    "LightSkyBlue",
    "LightSlateGray",
    "LightSteelBlue",
    "LimeGreen",
    "Linen",
    "Maroon",
    "MediumAquaMarine",
    "MediumBlue",
    "MediumOrchid",
    "MediumPurple",
    "MediumSeaGreen",
    "MediumSlateBlue",
    "MediumSpringGreen",
    "MediumTurquoise",
    "MediumVioletRed",
    "MidnightBlue",
    "NavajoWhite",
    "Navy",
    "Olive",
    "OliveDrab",
    "OrangeRed",
    "Orchid",
    "PaleGoldenRod",
    "PaleGreen",
    "PaleTurquoise",
    "PaleVioletRed",
    "PapayaWhip",
    "PeachPuff",
    "Peru",
    "Pink",
    "Plum",
    "PowderBlue",
    "RebeccaPurple",
    "RosyBrown",
    "RoyalBlue",
    "SaddleBrown",
    "Salmon",
    "SandyBrown",
    "SeaGreen",
    "Sienna",
    "Silver",
    "SkyBlue",
    "SlateBlue",
    "SlateGray",
    "SpringGreen",
    "SteelBlue",
    "Tan",
    "Teal",
    "Thistle",
    "Tomato",
    "Turquoise",
    "Violet",
    "Wheat",
    "YellowGreen",

    "AliceBlue",
    "AntiqueWhite",
    "Azure",
    "Beige",
    "Bisque",
    "Black",
    "BlanchedAlmond",
    "Cornsilk",
    "FloralWhite",
    "Gainsboro",
    "GhostWhite",
    "HoneyDew",
    "Ivory",
    "Lavender",
    "LavenderBlush",
    "LightYellow",
    "MintCream",
    "MistyRose",
    "Moccasin",
    "OldLace",
    "SeaShell",
    "Snow",
    "White",
    "WhiteSmoke",

  ];

}
