<p-table [columns]="cols"
         [value]="data"
         [frozenValue]="frozenData"
         [styleClass]="componentStyle"
         [tableStyleClass]="tableStyle"
         [selectionMode]="selectionMode"
         [(selection)]="selectedData"
         [contextMenu]="cxtMenu"
         [contextMenuSelectionMode]="( options.selectRows ? 'separate' : 'joint' )"
         [lazy]="lazy"
         [loading]="loading"
         [showLoader]="options.showLoader"
         [loadingIcon]="'far fa-spinner'"
         [totalRecords]="totalRowCount"
         [dataKey]="options.primaryKey"
         [paginator]="options.paging"
         [pageLinks]="(isMobile ? 3 : 5 )"
         [rows]="options.rowsPerPage"
         [sortMode]="options.sortMode"
         [showInitialSortBadge]="false"
         [responsive]="options.responsive"
         [resizableColumns]="options.resizableColumns"
         [columnResizeMode]="options.columnResizeMode"
         [reorderableColumns]="options.reorderColumns"
         [scrollable]="options.scrollable"
         [scrollHeight]="options.scrollHeight"
         [virtualScroll]="options.virtualScroll"
         [virtualScrollDelay]="500"
         [autoLayout]="true"
         [rowTrackBy]="trackByFn"
         [minBufferPx]="viewPortHeight"
         [maxBufferPx]="200"
         [(first)]="firstRowNumberToShow"
         (onPage)="firePage($event)"
         (onSort)="fireSort($event)"
         (onFilter)="fireFilter($event)"
         (onHeaderCheckboxToggle)="fireHeaderCheckboxToggle($event)"
         (onRowSelect)="fireRowSelect($event)"
         (onRowUnselect)="fireRowUnselect($event)"
         (onRowReorder)="fireRowReorder($event)"
         (onRowExpand)="fireRowExpand($event)"
         (onRowCollapse)="fireRowCollapse($event)"
         (onColResize)="fireColResize($event)"
         (onColReorder)="fireColReorder($event)"
         (onEditInit)="fireEditInit($event)"
         (onEditComplete)="fireEditComplete($event)"
         (onEditCancel)="fireEditCancel($event)"
         (onContextMenuSelect)="fireContextMenuSelect($event)"
         (onLazyLoad)="fireLazyLoad($event)"
         #dt>
  <ng-template [ngIf]="options.globalSearch || options.title || ( options.staticFilterPickList && options.staticFilterPickList.length > 0 ) || options.actionButtonLeft1 || options.actionButtonLeft2 || options.actionButtonRight1 || options.actionButtonRight2"
               pTemplate="caption">
    <div *ngIf="isMobilePortrait">
      <div class="row d-block">
        <!--<div *ngIf="options.globalSearch" class="float-start">
          <i [ngClass]="{'text-danger fas': globalFilterText, 'far': !globalFilterText, 'fa-search': true}" style="margin:10px 10px 0 0"></i>
        </div>-->
        <div *ngIf="options.title"
             class="float-start me-2">
          <h5>{{options.title}}</h5>
        </div>
        <div *ngIf="options.globalSearch"
             class="float-start">
          <ib-input-plain type="text"
                          size="small"
                          placeholder="Search"
                          [wrapperStyles]="'width:120px;'"
                          [standalone]="true"
                          [autofocus]="true"
                          [(ngModel)]="globalFilterText"
                          (keyUp)="filterGlobalOnInput($event)"
                          (change)="filterGlobalOnChange($event)"></ib-input-plain>
        </div>
        <div *ngIf="options.staticFilterPickList && options.staticFilterPickList.length > 0"
             class="float-start">
          <ib-input-select-plain name="Static Filter"
                                 size="small"
                                 tooltip="{{options.staticFilterLabel}}"
                                 [wrapperStyles]="'width:220px;'"
                                 [optionsPickList]="options.staticFilterPickList"
                                 [optionsIncludeNone]="false"
                                 [(ngModel)]="options.staticFilterValue">
          </ib-input-select-plain>
        </div>
        <div *ngIf="options.filterAllow"
             class="float-start">
          <ib-filter-selection-button [objectName]="options.filterObjectName"
                                      [data]="filterSelectionData"
                                      (change)="onFilterChange($event)">
          </ib-filter-selection-button>
        </div>
        <!-- <div class="clearfix"></div> -->
      </div>
      <div class="row d-block mt-2 ms-1">
        <div *ngIf="options.actionButtonLeft1"
             class="float-start me-2">
          <ib-action-button [button]="options.actionButtonLeft1"
                            [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
          </ib-action-button>
        </div>
        <div *ngIf="options.actionButtonLeft2"
             class="float-start me-2">
          <ib-action-button [button]="options.actionButtonLeft2"
                            [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
          </ib-action-button>
        </div>
        <div *ngIf="options.actionButtonRight2"
             class="float-end">
          <ib-action-button [button]="options.actionButtonRight2"
                            [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
          </ib-action-button>
        </div>
        <div *ngIf="options.actionButtonRight1"
             class="float-end me-2">
          <ib-action-button [button]="options.actionButtonRight1"
                            [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
          </ib-action-button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div *ngIf="!isMobilePortrait">
      <div *ngIf="options.title"
           class="float-start me-2">
        <h5>{{options.title}}</h5>
      </div>
      <div *ngIf="options.actionButtonLeft1"
           class="float-start me-2">
        <ib-action-button [button]="options.actionButtonLeft1"
                          [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
        </ib-action-button>
      </div>
      <div *ngIf="options.actionButtonLeft2"
           class="float-start me-2">
        <ib-action-button [button]="options.actionButtonLeft2"
                          [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
        </ib-action-button>
      </div>
      <div *ngIf="options.globalSearch"
           class="float-start">
        <!--<i class="far fa-search" style="margin:10px 10px 0 0"></i>-->
        <i [ngClass]="{'text-danger fas': globalFilterText, 'far': !globalFilterText, 'fa-search': true}"
           style="margin:10px 10px 0 0"></i>
      </div>
      <div *ngIf="options.globalSearch"
           class="float-start">
        <ib-input-plain type="text"
                        size="small"
                        placeholder="Search"
                        [wrapperStyles]="'width:300px;'"
                        [standalone]="true"
                        [autofocus]="true"
                        [(ngModel)]="globalFilterText"
                        (keyUp)="filterGlobalOnInput($event)"
                        (change)="filterGlobalOnChange($event)"></ib-input-plain>
      </div>
      <div *ngIf="options.staticFilterPickList && options.staticFilterPickList.length > 0"
           class="float-start">
        <ib-input-select-plain name="Static Filter"
                               size="small"
                               tooltip="{{options.staticFilterLabel}}"
                               [wrapperStyles]="'width:300px;'"
                               [optionsPickList]="options.staticFilterPickList"
                               [optionsIncludeNone]="false"
                               [(ngModel)]="options.staticFilterValue">
        </ib-input-select-plain>
      </div>
      <div *ngIf="options.filterAllow"
           class="float-start ms-1">
        <ib-filter-selection-button [objectName]="options.filterObjectName"
                                    [data]="filterSelectionData"
                                    (change)="onFilterChange($event)">
        </ib-filter-selection-button>
      </div>
      <div *ngIf="options.actionButtonRight2"
           class="float-end">
        <ib-action-button [button]="options.actionButtonRight2"
                          [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
        </ib-action-button>
      </div>
      <div *ngIf="options.actionButtonRight1"
           class="float-end me-2">
        <ib-action-button [button]="options.actionButtonRight1"
                          [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
        </ib-action-button>
      </div>
      <div class="clearfix"></div>
    </div>
  </ng-template>
  <ng-template pTemplate="header"
               let-columns>
    <tr>
      <th *ngIf="options.expandRows"
          style="width:3em;"></th>
      <th *ngIf="options.reorderRows"
          style="width:2.5em;"></th>
      <th *ngIf="options.selectRows"
          style="width:3em;">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns;trackBy: trackByFn"
          pResizableColumn
          pReorderableColumn>
        <div class="float-start"
             [pSortableColumn]="col.field"
             [pSortableColumnDisabled]="!col.sortable">
          <ib-icon [icon]="col.options.headerIcon"
                   [label]="col.header"
                   [tooltip]="col.options.headerTooltip">
          </ib-icon>
          <!--{{col.header}}-->
          <p-sortIcon *ngIf="options.sortMode !== 'none' && col.sortable"
                      [field]="col.field"></p-sortIcon>
        </div>
        <div *ngIf="options.filterColumns && col.filterType !== 'none'"
             class="float-start"
             style="padding: 1px 5px 1px 5px; cursor:pointer;"
             (click)="filterColumn($event, col, filterPanel)">
          <i
             [ngClass]="{'text-danger fas': (col.options.filterValue||col.options.filterSelections.length>0), 'far': (!col.options.filterValue&&col.options.filterSelections.length===0), 'fa-filter': true}"></i>
        </div>
      </th>
      <th *ngIf="options.rowActionButton"
          style="width:2.5em;">
        <div class="text-center"
             (mouseover)="tableConfigOptionActive = true;"
             (mouseout)="tableConfigOptionActive = false;">
          <i *ngIf="!options.canModifyLayout || !tableConfigOptionActive"
             class="far fa-bars"></i>
          <i *ngIf="options.canModifyLayout && tableConfigOptionActive"
             class="fas fa-cog text-primary clickable"
             (click)="onTableConfig($event)"></i>
        </div>
      </th>
    </tr>
    <tr *ngFor="let headerRow of headerData;trackBy: trackByFn; let headerIndex = index"
        [style]="getRowStyle(headerRow, 'background-color: #0060ab; color: #ffffff; font-size: larger; font-weight: bolder; font-style: italic; border-bottom: black 2px solid;')">
      <td *ngIf="options.reorderRows"
          style="width:2.5em;">
        <!--<i class="far fa-arrows" pReorderableRowHandle></i>-->
      </td>
      <td *ngIf="options.selectRows"
          style="width:3em;">
        <!--<p-tableCheckbox [value]="rowData"></p-tableCheckbox>-->
      </td>
      <ng-container *ngFor="let col of columns;trackBy: trackByFn">
        <td *ngIf="!options.inlineEdit || !col.editable"
            class="ui-resizable-column"
            [style]="getCellStyle(col, headerRow[col.field], headerRow, 'padding: 5px 0 5px 12px;')">
          <ib-standard-table-cell-output [col]="col"
                                         [options]="col.options"
                                         [data]="getModel(headerRow, col.field, headerIndex)"
                                         [supportingData]="getModel(headerRow, col.options.supportingPropertyName, headerIndex)"
                                         [row]="headerRow"></ib-standard-table-cell-output>
        </td>
        <td *ngIf="options.inlineEdit && col.editable"
            class="ui-resizable-column"
            pEditableColumn
            [style]="getCellStyle(col, headerRow[col.field], headerRow, 'padding: 5px 0 5px 12px;')">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ib-standard-table-cell-input [col]="col"
                                            [options]="col.options"
                                            [ngModel]="getModel(headerRow, col.field, headerIndex)"
                                            (ngModelChange)="onModelChange($event, headerRow, col.field)">
              </ib-standard-table-cell-input>
            </ng-template>
            <ng-template pTemplate="output">
              <ib-standard-table-cell-output [col]="col"
                                             [options]="col.options"
                                             [data]="getModel(headerRow, col.field, headerIndex)"
                                             [supportingData]="getModel(headerRow, col.options.supportingPropertyName, headerIndex)"
                                             [row]="headerRow"
                                             [refreshCount]="otherChangeCount"></ib-standard-table-cell-output>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-container>
      <ng-container *ngIf="options.rowActionButton">
        <ng-container *ngTemplateOutlet="rowMenuCell; context: {cellId: 'rowMenuCell_header_' + headerIndex, rowData: headerRow, rowIndex: -1, headerIndex: headerIndex}">
        </ng-container>
      </ng-container>
      <!-- <td *ngIf="options.rowActionButton"
          id="rowMenuCell_header_{{headerIndex}}"
          style="width:2.5em;"
          class="rowMenuCell"
          (click)="showMenu(rowMenu,$event,headerRow,-1,headerIndex)">
        <div class="text-center rowMenuCell">
          <ib-icon icon="bars"
                   class="rowMenuCell"></ib-icon>
        </div>
      </td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="frozenbody"
               let-rowData
               let-columns="columns"
               let-index="rowIndex">
    <tr [pContextMenuRow]="rowData"
        [pReorderableRow]="index"
        [ngClass]="{ 'clickable': options.selectRowViaRowClick }"
        [style]="getRowStyle(rowData)"
        (mousedown)="fireTableRowClick($event, index, rowData)">
      <!--
        These attributes fail when checkbox is enabled so we revert to (mousedown) to handle selecting via row click
        [pSelectableRow]="rowData" [pSelectableRowIndex]="index"
      -->
      <td *ngIf="options.expandRows"
          style="width:3em;"></td>
      <td *ngIf="options.reorderRows"
          style="width:2.5em;">
        <span class="far fa-arrows"
              [pReorderableRowHandle]></span>
      </td>
      <td *ngIf="options.selectRows"
          style="width:3em;">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <ng-container *ngFor="let col of columns;trackBy: trackByFn">
        <td *ngIf="!options.inlineEdit || !col.editable"
            class="ui-resizable-column"
            [style]="getCellStyle(col, rowData[col.field], rowData)">
          <ib-standard-table-cell-output [col]="col"
                                         [options]="col.options"
                                         [data]="getModel(rowData, col.field, index)"
                                         [supportingData]="getModel(rowData, col.options.supportingPropertyName, index)"
                                         [row]="rowData"></ib-standard-table-cell-output>
        </td>
        <td *ngIf="options.inlineEdit && col.editable"
            class="ui-resizable-column"
            pEditableColumn
            [style]="getCellStyle(col, rowData[col.field], rowData)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ib-standard-table-cell-input [col]="col"
                                            [options]="col.options"
                                            [ngModel]="getModel(rowData, col.field, index)"
                                            (ngModelChange)="onModelChange($event, rowData, col.field)">
              </ib-standard-table-cell-input>
            </ng-template>
            <ng-template pTemplate="output">
              <ib-standard-table-cell-output [col]="col"
                                             [options]="col.options"
                                             [data]="getModel(rowData, col.field, index)"
                                             [supportingData]="getModel(rowData, col.options.supportingPropertyName, index)"
                                             [row]="rowData"></ib-standard-table-cell-output>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-container>
      <ng-container *ngIf="options.rowActionButton">
        <ng-container *ngTemplateOutlet="rowMenuCell; context: {cellId: 'rowMenuCell_' + index, rowData: rowData, rowIndex: index, headerIndex: -1}">
        </ng-container>
      </ng-container>
      <!-- <td *ngIf="options.rowActionButton"
          id="rowMenuCell_{{index}}"
          style="width:2.5em;"
          (click)="showMenu(rowMenu,$event,rowData,index)">
        <div class="text-center">
          <ib-icon icon="bars"></ib-icon>
        </div>
      </td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-rowData
               let-expanded="expanded"
               let-columns="columns"
               let-index="rowIndex">
    <tr [pContextMenuRow]="rowData"
        [pReorderableRow]="index"
        [ngClass]="{ 'clickable': options.selectRowViaRowClick }"
        [style]="getRowStyle(rowData)"
        (mousedown)="fireTableRowClick($event, index, rowData)">
      <!--
        These attributes fail when checkbox is enabled so we revert to (mousedown) to handle selecting via row click
        [pSelectableRow]="rowData" [pSelectableRowIndex]="index"
      -->
      <!--<tr [pSelectableRow]="rowData" [pContextMenuRow]="rowData" [pReorderableRow]="index" [pSelectableRowIndex]="index"
      [style]="getRowStyle(rowData)" (mousedown)="fireTableRowClick($event, index, rowData)">-->
      <!--[pReorderableRowDisabled]="options.selectRows"-->
      <td *ngIf="options.expandRows"
          class="expand-row-icon"
          style="width:3em;">
        <a [pRowToggler]="rowData"
           class="expand-row-icon">
          <i class="expand-row-icon"
             [ngClass]="expanded ? 'far fa-chevron-down fa-fw' : 'far fa-chevron-right fa-fw'"></i>
        </a>
      </td>
      <td *ngIf="options.reorderRows"
          style="width:2.5em;">
        <span class="far fa-arrows"
              [pReorderableRowHandle]></span>
      </td>
      <td *ngIf="options.selectRows"
          style="width:3em;">
        <p-tableCheckbox [value]="rowData"
                         [index]="index"></p-tableCheckbox>
      </td>
      <ng-container *ngFor="let col of columns;trackBy: trackByFn">
        <td *ngIf="!options.inlineEdit || !col.editable"
            class="ui-resizable-column"
            [style]="getCellStyle(col, rowData[col.field], rowData)">
          <span class="p-column-title">{{ col.header }}</span>
          <ib-standard-table-cell-output class="d-inline-block"
                                         [col]="col"
                                         [options]="col.options"
                                         [data]="getModel(rowData, col.field, index)"
                                         [supportingData]="getModel(rowData, col.options.supportingPropertyName, index)"
                                         [row]="rowData"></ib-standard-table-cell-output>
        </td>
        <td *ngIf="options.inlineEdit && col.editable"
            class="ui-resizable-column"
            pEditableColumn
            [style]="getCellStyle(col, rowData[col.field], rowData)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ib-standard-table-cell-input [col]="col"
                                            [options]="col.options"
                                            [ngModel]="getModel(rowData, col.field, index)"
                                            (ngModelChange)="onModelChange($event, rowData, col.field)">
              </ib-standard-table-cell-input>
            </ng-template>
            <ng-template pTemplate="output">
              <ib-standard-table-cell-output [col]="col"
                                             [options]="col.options"
                                             [data]="getModel(rowData, col.field, index)"
                                             [supportingData]="getModel(rowData, col.options.supportingPropertyName, index)"
                                             [row]="rowData"></ib-standard-table-cell-output>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-container>
      <ng-container *ngIf="options.rowActionButton">
        <ng-container *ngTemplateOutlet="rowMenuCell; context: {cellId: 'rowMenuCell_' + contextMenuRowIndex, rowData: rowData, rowIndex: index, headerIndex: -1}">
        </ng-container>
      </ng-container>
      <!-- <td *ngIf="options.rowActionButton"
          id="rowMenuCell_{{contextMenuRowIndex}}"
          style="width:2.5em;">
        <div class="text-center">
          <ng-container *ngFor="let action of rowActionsForIconDisplay">
            <ib-icon [icon]="action.icon"
                     [tooltip]="action.description || action.label"
                     (click)="fireAction(action,$event,rowData,index)"></ib-icon>
          </ng-container>
          <ib-icon icon="bars"
                   (click)="showMenu(rowMenu,$event,rowData,index)"></ib-icon>
        </div>
      </td> -->
    </tr>
  </ng-template>
  <ng-template *ngIf="options.footer"
               pTemplate="footer"
               let-columns>
    <tr>
      <td *ngIf="options.expandRows"></td>
      <td *ngIf="options.reorderRows"></td>
      <td *ngIf="options.selectRows"></td>
      <td *ngFor="let col of columns">
        <div *ngIf="col.options && col.options.footerHtml"
             [innerHTML]="col.options.footerHtml | safe : 'html'">
        </div>
      </td>
      <td *ngIf="options.rowActionButton"></td>
    </tr>
  </ng-template>
  <ng-template *ngIf="options.expandRows"
               pTemplate="rowexpansion"
               let-rowData
               let-columns="columns">
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <!-- +2 to cover the row expander icon and the row menu icon-->
        <ng-container *ngIf="options.expandedRowHtmlBuilder">
          <div [innerHTML]="getRowExpandedHtml(rowData, -1)"></div>
        </ng-container>
        <div *ngIf="options.expandedRowChildTableOptions && options.expandedRowChildDataPropertyName"
             class="ps-5">
          <ib-standard-table *ngIf="options.expandedRowChildDataPropertyName"
                             [options]="options.expandedRowChildTableOptions"
                             [loading]="options.expandedRowChildDataLoading"
                             [data]="getModel(rowData, options.expandedRowChildDataPropertyName, -1)"
                             [reloadCount]="reloadCount">
          </ib-standard-table>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"
               let-state>
    <div *ngIf="options.rowCounterMessage || (options.showRefreshOption && options.loadDataFromServer)"
         [ngClass]="{ 'vw-100 mb-5 ms-5': isMobilePortrait }">
      <div *ngIf="options.rowCounterMessage"
           class="ui-table-rows-message float-start me-2"
           [ngClass]="{ '': isMobilePortrait }">
        <em *ngIf="totalRowCount !== filteredRowCount">{{filteredRowCount | number}} filtered rows
          ({{totalRowCount |
          number}} total rows)</em>
        <em *ngIf="totalRowCount === filteredRowCount">{{totalRowCount | number}} total rows</em>
        <em *ngIf="lazyDataFiltered"> (filtered)</em>
      </div>
      <div *ngIf="options.showRefreshOption && options.loadDataFromServer"
           class="ui-table-rows-message float-start"
           [ngClass]="{ '': isMobilePortrait }">
        <i (click)="fireLazyLoad($event,true)"
           [hidden]="loading"
           class="far fa-sync clickable"></i>
        <i [hidden]="!loading"
           class="far fa-spinner fa-spin"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="paginatorright">
    <div *ngIf="options.rowsPerPageSelector"
         [ngClass]="{ 'vw-100 mt-4 ms-5': isMobilePortrait }">
      <div *ngIf="options.rowsPerPageSelector"
           class="float-start"
           [ngClass]="{ '': isMobilePortrait }">
        <select class="form-control form-control-sm form-select"
                name="rowPerPage"
                [ngModelOptions]="{standalone: true}"
                (change)="setRowsPerPage($event)"
                [ngModel]="options.rowsPerPage">
          <option *ngFor="let option of rowsPerPageOptions;trackBy: trackByFn"
                  [value]="option.rows">
            {{option.label}}
          </option>
        </select>
      </div>
    </div>
  </ng-template>
</p-table>

<p-overlayPanel #filterPanel
                styleClass="p-table-column-filter-overlay"
                appendTo="body">
  <div *ngIf="currentFilterCol && currentFilterCol.filterType === 'text'"
       style="min-height:38px; min-width:300px;">
    <div class="input-group">
      <div ngbDropdown>
        <button ngbDropdownToggle
                class="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">{{filterMatchModeLabel()}}</button>
        <div ngbDropdownMenu
             class="dropdown-menu">
          <a class="dropdown-item"
             (click)="filterMatchModeSet('contains')">Contains</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('startsWith')">Starts With</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('endsWith')">Ends With</a>
          <div role="separator"
               class="dropdown-divider"></div>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('equals')">=</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('notEquals')">!=</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('lt')">&lt;</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('lte')">&lt;=</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('gt')">&gt;</a>
          <a class="dropdown-item"
             (click)="filterMatchModeSet('gte')">&gt;=</a>
        </div>
      </div>
      <input #filterText
             type="text"
             class="form-control"
             placeholder="Filter"
             autofocus
             [(ngModel)]="currentFilterCol.options.filterValue"
             (input)="filterColumnOnInput($event)"
             (change)="filterColumnOnChange($event)">
    </div>
  </div>
  <!--<div *ngIf="currentFilterCol && currentFilterCol.filterType === 'daterange'" style="min-width:300px;">
    <ib-input-select-plain name="Date Range" label="Date Range"
                           size="small"
                           class="float-start w-100"
                           format="pull-left"
                           [plainInput]="true"
                           wrapperClasses="p-0 w-100"
                           [optionsPickList]="dateRangeOptionsPickList"
                           [optionsIncludeNone]="false"
                           [(ngModel)]="currentFilterCol.options.filterDateRange">
    </ib-input-select-plain>
    <div *ngIf="currentFilterCol.options.filterDateRange === 'CUSTOM'" class="clearfix w-100 pt-2">
      <ib-input-date type="date" size="small" placeholder="Start"
                     class="float-start"
                     format="pull-left"
                     [plainInput]="true"
                     wrapperClasses="ps-0"
                     [(ngModel)]="currentFilterCol.options.filterDateValue1">
      </ib-input-date>
      <span class="float-start me-2">&nbsp;and&nbsp;</span>
      <ib-input-date type="date" size="small" placeholder="End"
                     class="float-start"
                     format="pull-left"
                     [plainInput]="true"
                     wrapperClasses="ps-0"
                     [(ngModel)]="currentFilterCol.options.filterDateValue2">
      </ib-input-date>
    </div>
  </div>-->
  <div *ngIf="currentFilterCol && currentFilterCol.filterType === 'select'"
       style="min-height:40px; min-width:300px;">
    <p-listbox [options]="filterSelectOptions[currentFilterCol.field]"
               [(ngModel)]="currentFilterCol.options.filterValue"
               [style]="{minWidth:'300px', maxWidth:'500px', maxHeight:'300px'}"
               [listStyle]="{maxHeight:'200px'}"
               styleClass="p-table-filter-multiselect-container"
               [multiple]="false"
               [checkbox]="false"
               [filter]="true"
               filterPlaceHolder="Search Items"
               (onChange)="dt.filter($event.value, currentFilterCol.field, 'equals')">
      <p-header *ngIf="currentFilterCol.options.filterValue">
        <span style="cursor:pointer;"
              (click)="filterColumnClear()"><i class="far fa-filter"></i> Clear
          Selection</span>
      </p-header>
    </p-listbox>
  </div>
  <div *ngIf="currentFilterCol && currentFilterCol.filterType === 'multiselect'"
       style="min-height:40px; min-width:300px;">
    <p-listbox [options]="filterSelectOptions[currentFilterCol.field]"
               [(ngModel)]="currentFilterCol.options.filterSelections"
               [style]="{minWidth:'300px', maxWidth:'500px', maxHeight:'300px'}"
               [listStyle]="{maxHeight:'200px'}"
               styleClass="p-table-filter-multiselect-container"
               [multiple]="true"
               [checkbox]="true"
               [filter]="true"
               filterPlaceHolder="Search Items"
               (onChange)="dt.filter($event.value, currentFilterCol.field, 'in')">
    </p-listbox>
  </div>
</p-overlayPanel>
<p-contextMenu #cxtMenu
               [model]="contextMenu"
               appendTo="body"
               [baseZIndex]="2000"></p-contextMenu>
<p-menu #rowMenu
        [popup]="true"
        [model]="contextMenu"
        appendTo="body"></p-menu>



<ng-template #rowMenuCell
             let-cellId="cellId"
             let-rowData="rowData"
             let-rowIndex="rowIndex"
             let-headerIndex="headerIndex">
  <td id="{{cellId}}"
      [style]="rowMenuWidth">
    <div class="text-center">
      <ng-container *ngFor="let action of rowActionsForIconDisplay">
        <ng-container *ngIf="actionIsVisible(action,rowData)">
          <ib-icon class="me-2"
                   [icon]="action.icon"
                   [tooltip]="action.label"
                   tooltipPlacement="left"
                   (click)="actionExecute(action,$event,rowData,rowIndex,headerIndex)"></ib-icon>
        </ng-container>
      </ng-container>
      <div *ngIf="this.options.rowActionDisplayMode === 'menu' || this.options.rowActionDisplayMode === 'overflow'"
           style="display:inline;"
           (click)="showMenu(rowMenu,$event,rowData,rowIndex,headerIndex)">
        <ib-icon icon="bars"></ib-icon>
      </div>
    </div>
  </td>
</ng-template>
