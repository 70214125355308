<ib-form-render *ngIf="formModel && formData"
                [formModel]="formModel"
                mode="add"
                [data]="formData"
                [loading]="loadingCount > 0"
                [working]="working"
                [saveCount]="saveCount"
                [cancelCount]="cancelCount"
                [error]="error"
                (status)="updateFormStatus($event)"
                (addSave)="submitRefund($event)">
</ib-form-render>
<div class="alert alert-danger mt-1" role="alert" *ngIf="error && (!formModel || !formData)">
  {{error.message}}
</div>
