<div
     *ngIf="options.globalSearch || options.title || ( options.staticFilterPickList && options.staticFilterPickList.length > 0 ) || options.actionButtonLeft1 || options.actionButtonLeft2 || options.actionButtonRight1 || options.actionButtonRight2">
  <div *ngIf="options.title"
       class="float-start me-2">
    <h5>{{options.title}}</h5>
  </div>
  <div *ngIf="options.actionButtonLeft1"
       class="float-start me-2">
    <ib-action-button [button]="options.actionButtonLeft1"
                      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div *ngIf="options.actionButtonLeft2"
       class="float-start me-2">
    <ib-action-button [button]="options.actionButtonLeft2"
                      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div *ngIf="options.globalSearch"
       class="float-start">
    <i [ngClass]="{'text-danger fas': globalFilterText, 'far': !globalFilterText, 'fa-search': true}"
       style="margin:10px 10px 0 0"></i>
  </div>
  <div *ngIf="options.globalSearch"
       class="float-start">
    <ib-input-plain type="text"
                    size="small"
                    placeholder="Search"
                    [wrapperStyles]="'width:300px;'"
                    [standalone]="true"
                    [(ngModel)]="globalFilterText"
                    (keyUp)="filterGlobalOnInput($event)"
                    (change)="filterGlobalOnChange($event)">
    </ib-input-plain>
  </div>
  <div *ngIf="options.staticFilterPickList && options.staticFilterPickList.length > 0"
       class="float-start">
    <ib-input-select-plain name="Static Filter"
                           size="small"
                           tooltip="{{options.staticFilterLabel}}"
                           [wrapperStyles]="'width:300px;'"
                           [optionsPickList]="options.staticFilterPickList"
                           [optionsIncludeNone]="false"
                           [(ngModel)]="options.staticFilterValue">
    </ib-input-select-plain>
  </div>
  <div *ngIf="options.actionButtonRight2"
       class="float-end">
    <ib-action-button [button]="options.actionButtonRight2"
                      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div *ngIf="options.actionButtonRight1"
       class="float-end me-2">
    <ib-action-button [button]="options.actionButtonRight1"
                      [data]="{ data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }">
    </ib-action-button>
  </div>
  <div class="clearfix"></div>
</div>
<div *ngIf="options.virtualScroll; else noVirtualScroll">
  <div
      class="p-datatable-component-plain p-datatable ui-widget p-datatable-responsive p-datatable-resizable p-datatable-resizable-fit p-datatable-hoverable-rows p-datatable-auto-layout" >
    <div class="p-datatable-wrapper">
      <cdk-virtual-scroll-viewport itemSize="50" class="cdk-viewport">
        <table  class="p-datatable-striped"
              style="width:100%;">
          <!--class="table table-hover table-sm">-->
          <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
          <tbody class="p-datatable-tbody"
                cdkDropList
                [cdkDropListData]="data"
                (cdkDropListDropped)="rowDrop($event)"
                #tablebody>
            <tr class="ui-selectable-row"
                *cdkVirtualFor="let row of data; let index = index; trackBy: trackByIndex"
                [style]="getRowStyle(row)"
                cdkDrag
                [cdkDragData]="row"
                [cdkDragLockAxis]="dragLockAxis"
                [cdkDragDisabled]="!options.reorderRows">
              <!--<div class="drop-placeholder" *cdkDragPlaceholder></div>-->
              <td class="drag-col-xs ui-resizable-column"
                  *ngIf="options.reorderRows"
                  style="width:2.5em;">
                <i class="far fa-arrows"
                  style="cursor:move;"
                  cdkDragHandle></i>
              </td>
              <td class="drag-col-md ui-resizable-column"
                  *ngFor="let col of options.columns;trackBy: trackByIndex"
                  (mousedown)="onRowClick($event, row, index)">
                <!--{{row[col.propertyName]}}-->
                <ib-standard-table-cell-output [options]="col"
                                              [data]="getModel(row, col.propertyName, index)"
                                              [row]="row"
                                              [refreshCount]="otherChangeCount"></ib-standard-table-cell-output>
              </td>
              <td class="drag-col-xs ui-resizable-column"
                  *ngIf="options.rowActionButton"
                  style="width:2.5em;">
                <ib-action-button [button]="options.rowActionButton"
                                  [data]="row"
                                  [cargo]="{ index: index }">
                </ib-action-button>
              </td>
            </tr>
          </tbody>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <p-contextMenu #cxtMenu
                [target]="tablebody"
                [model]="contextMenu"
                appendTo="body"
                [baseZIndex]="2000"></p-contextMenu>
</div>

<ng-template #noVirtualScroll>
  <div
       class="p-datatable-component-plain p-datatable ui-widget p-datatable-responsive p-datatable-resizable p-datatable-resizable-fit p-datatable-hoverable-rows p-datatable-auto-layout">
    <div class="p-datatable-wrapper">
      <table class="p-datatable-striped"
              style="width:100%;">
        <!--class="table table-hover table-sm">-->
        <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
        <tbody class="p-datatable-tbody"
                cdkDropList
                [cdkDropListData]="data"
                (cdkDropListDropped)="rowDrop($event)"
                #tablebody>
          <tr class="ui-selectable-row"
              *ngFor="let row of data; let index = index; trackBy: trackByIndex"
              [style]="getRowStyle(row)"
              cdkDrag
              [cdkDragData]="row"
              [cdkDragLockAxis]="dragLockAxis"
              [cdkDragDisabled]="!options.reorderRows">
            <!--<div class="drop-placeholder" *cdkDragPlaceholder></div>-->
            <td class="drag-col-xs ui-resizable-column"
                *ngIf="options.reorderRows"
                style="width:2.5em;">
              <i class="far fa-arrows"
                  style="cursor:move;"
                  cdkDragHandle></i>
            </td>
            <td class="drag-col-md ui-resizable-column"
                *ngFor="let col of options.columns;trackBy: trackByIndex"
                (mousedown)="onRowClick($event, row, index)">
              <!--{{row[col.propertyName]}}-->
              <ib-standard-table-cell-output [options]="col"
                                              [data]="getModel(row, col.propertyName, index)"
                                              [row]="row"
                                              [refreshCount]="otherChangeCount"></ib-standard-table-cell-output>
            </td>
            <td class="drag-col-xs ui-resizable-column"
                *ngIf="options.rowActionButton"
                style="width:2.5em;">
              <ib-action-button [button]="options.rowActionButton"
                                [data]="row"
                                [cargo]="{ index: index }">
              </ib-action-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p-contextMenu #cxtMenu
                 [target]="tablebody"
                 [model]="contextMenu"
                 appendTo="body"
                 [baseZIndex]="2000"></p-contextMenu>
</ng-template>


<ng-template #tableHeader>
  <thead class="p-datatable-thead">
    <tr>
      <th *ngIf="options.reorderRows"
          style="width:2.5em;"></th>
      <th *ngFor="let col of options.columns;trackBy: trackByIndex">
        <div class="float-start">
          <ib-icon [icon]="col.headerIcon"
                  [label]="col.header"
                  [tooltip]="col.headerTooltip"></ib-icon>
          <!--<p-sortIcon *ngIf="options.sortMode !== 'none' && col.sortable" [field]="col.field"></p-sortIcon>-->
        </div>
        <!--<div *ngIf="options.filterColumns && col.filterType !== 'none'" class="float-start" style="padding: 1px 5px 1px 5px; cursor:pointer;" (click)="filterColumn($event, col, filterPanel)">
                        <i [ngClass]="{'text-danger fas': (col.filterValue||col.filterSelections.length>0), 'far': (!col.filterValue&&col.filterSelections.length===0), 'fa-filter': true}"></i>
                      </div>-->
      </th>
      <th *ngIf="options.rowActionButton"
          style="width:2.5em;">
        <div class="text-center">
          <ib-icon icon="bars"></ib-icon>
        </div>
      </th>
    </tr>
  </thead>
</ng-template>


