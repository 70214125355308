import { Component, OnInit, OnDestroy, OnChanges, AfterViewInit, Input } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { KioskSettings } from 'projects/ib-kiosk-app/src/app/models/kiosk-settings';
import { differenceInMinutes } from 'date-fns';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5pay from "projects/core-lib/src/lib/models/ngModelsPayment5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { ApiProperties, ApiCall, ApiOperationType, IApiResponseWrapperTyped, Query } from 'projects/core-lib/src/lib/api/ApiModels';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBaseClass } from 'projects/common-lib/src/lib/form/form-base-class';
import { CanComponentDeactivate } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ActivatedRoute } from '@angular/router';
import { TranslationHelperService } from 'projects/core-lib/src/lib/services/translation-helper.service';
import { ApiModulePayment } from 'projects/core-lib/src/lib/api/Api.Module.Payment';
import { ApiModuleWeb } from 'projects/core-lib/src/lib/api/Api.Module.Web';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormMessageModel } from 'projects/core-lib/src/lib/models/form-status-model';
import { UxService } from '../../../../common-lib/src/lib/services/ux.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
  providers: [FormStatusService]
})
export class RefundComponent extends FormBaseClass<m5pay.PaymentTransactionRefundAddViewModel> implements OnInit, OnChanges, OnDestroy, AfterViewInit, CanComponentDeactivate {

  @Input() paymentId: string = null;

  settings: KioskSettings = null;
  formModel: m5web.FormEditViewModel = null;
  //formErrorModel: m5web.FormEditViewModel = null;
  formData: RefundFormModel = null;

  loadingCount: number = 0;
  working: boolean = false;
  /**
  This is submitted to the form component to signal there is a form error.
  */
  error: FormMessageModel;
  /**
  This is submitted to the form component to signal when a save is successful by incrementing the count.  The form will then decide how to act.
  */
  saveCount: number = 0;
  /**
  This is submitted to the form component to signal when a cancel has been triggered by incrementing the count.  The form will then decide how to act.
  */
  cancelCount: number = 0;

  constructor(
    protected route: ActivatedRoute,
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected formService: FormStatusService,
    protected translation: TranslationHelperService) {

    super(appService, uxService, formService, false, null);

    this.settings = this.appService.config.settings;

  }

  ngOnInit() {

    super.ngOnInit();

    // Sometimes we get our id via input attribute (e.g. when rendered from home component) and
    // sometimes we get our id via route parameters (e.g. url was direct to this route)
    const id: string = this.route.snapshot.params["id"];
    if (id) {
      this.paymentId = id;
    }

    this.setupPage();

    this.loadForm();

    this.loadPayment();

  }

  setupPage() {

    if (this.settings.refund.documentTitle) {
      this.appService.title = this.translation.getTranslation(this.settings.refund.documentTitle);
    }

  }


  loadForm() {

    if (!this.settings.refund) {
      Log.errorMessage("No refund settings found in configuration.");
      let message = Helper.getFirstDefinedString(this.settings.refund.paymentLoadErrorMessage, "No refund settings found in configuration.");
      message = this.translation.getTranslation(message);
      this.error = new FormMessageModel("config", message, this.translation.currentLanguage, false);
      return;
    }

    if (this.settings.refund.refundFormId) {
      const apiProp: ApiProperties = ApiModuleWeb.Form();
      const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      this.loadingCount++;
      this.apiService.execute(apiCall, this.settings.refund.refundFormId).subscribe((result: IApiResponseWrapperTyped<m5web.FormEditViewModel>) => {
        if (result.Data.Success) {
          this.formModel = result.Data.Data;
          this.loadingCount--;
        } else {
          //this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          let message = Helper.getFirstDefinedString(this.settings.refund.paymentLoadErrorMessage, "Error loading refund form: {{Message}}");
          message = this.translation.getTranslation(message, { Message: result.Data.Message });
          this.error = new FormMessageModel("config", message, this.translation.currentLanguage, false);
          this.formModel = null;
          this.loadingCount--;
        }
      });
    }

    //if (this.settings.refund.refundErrorFormId) {
    //  let apiProp: ApiProperties = Api.Form();
    //  let apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    //  this.loadingCount++;
    //  this.apiService.execute(apiCall, this.settings.refund.refundErrorFormId).subscribe((result: IApiResponseWrapperTyped<m5.FormEditViewModel>) => {
    //    if (result.Data.Success) {
    //      this.formErrorModel = result.Data.Data;
    //      this.loadingCount--;
    //    } else {
    //      this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
    //      let message = Helper.getFirstDefinedString(this.settings.refund.paymentLoadErrorMessage, "Error loading refund form: {{Message}}");
    //      message = this.translation.getTranslation(message, { Message: result.Data.Message });
    //      this.error = new FormMessageModel("config", message, this.translation.currentLanguage, false);
    //      this.formErrorModel = null;
    //      this.loadingCount--;
    //    }
    //  });
    //}

  }


  loadPayment() {

    if (!this.paymentId) {
      Log.errorMessage("No payment id provided for refund.");
      // We are typically getting our preferred language from the contact record returned with the payment but we won't
      // have that in this case so default to the browser language.
      this.translation.preferredLanguage = this.translation.browserLanguage;
      const message = Helper.getFirstDefinedString(this.settings.refund.paymentIdNotProvidedMessage, "No payment id provided.  Please provide a payment id and try again.");
      this.translation.getTranslationObservable(message).pipe(takeUntil(this.ngUnsubscribe)).subscribe(translated => {
        this.error = new FormMessageModel("no-id", translated, this.translation.currentLanguage, false);
        this.formData = new RefundFormModel();
        this.formData.Error = this.error;
      });
      return;
    }

    const apiProp: ApiProperties = ApiModulePayment.PaymentTransactionGetByIdType();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    this.loadingCount++;
    // In kiosk scenario we're running in user context of an api key but we do care about which user this is for so pass ?expand=Contact query string
    // parameter so we get the contact object back in our meta data of the response for determining things like their preferred language, etc.
    this.apiService.execute(apiCall, { PaymentTransactionId: this.paymentId, IdType: this.settings.refund.paymentIdType, RequestType: "P", Expand: "Contact" }).subscribe((result: IApiResponseWrapperTyped<m5pay.PaymentTransactionEditViewModel>) => {
      if (result.Data.Success) {
        // See if we have a language for this anonymous kiosk user
        if (result.Data.Meta && result.Data.Meta.Contact && result.Data.Meta.Contact.Language) {
          this.translation.preferredLanguage = result.Data.Meta.Contact.Language;
        }
        // Build out our form data
        this.formData = new RefundFormModel();
        this.formData.Purchase = result.Data.Data;
        if (result.Data.Meta && result.Data.Meta.Contact) {
          this.formData.Contact = result.Data.Meta.Contact;
        }
        if (!this.formData.Contact.Language) {
          this.formData.Contact.Language = "en";
        }
        this.formData.Refund.PaymentTransactionRequestDateTime = this.formData.Purchase.RequestDateTime;
        // While we have a variety of id values that we now know from the payment we should only use the
        // one we searched on in our refund since this is an open kiosk app and we would benefit from
        // having the payload limited to the minimum information needed to perform the job.
        if (this.settings.refund.paymentIdType === "PaymentTransactionId") {
          this.formData.Refund.PaymentTransactionId = this.formData.Purchase.PaymentTransactionId;
        } else if (this.settings.refund.paymentIdType === "TransactionOrderReference") {
          this.formData.Refund.TransactionOrderReference = this.formData.Purchase.TransactionOrderReference;
        } else if (this.settings.refund.paymentIdType === "TransactionPaymentId") {
          this.formData.Refund.PaymentProviderTransactionPaymentId = this.formData.Purchase.TransactionPaymentId;
        }
        this.formData.Refund.IsoCurrencyCode = this.formData.Purchase.IsoCurrencyCode;
        this.formData.Refund.Amount = ((this.formData.Purchase.Amount || 0) - (this.formData.Purchase.RefundedAmount || 0));
        this.formData.Refund.Attributes = this.formData.Purchase.Attributes;
        this.formData.Refund.TimeZone = this.formData.Purchase.TimeZone;
        // Note that for various error messages we get our translation as an observable because we just barely set our
        // language above (see result.Data.Meta.Contact.Language) and need time to download the language json file.
        // If already refunded post as error
        if (this.formData.Refund.Amount <= 0) {
          const message = Helper.getFirstDefinedString(this.settings.refund.paymentAlreadyRefundedMessage, "This purchase has already been refunded.");
          this.translation.getTranslationObservable(message).pipe(takeUntil(this.ngUnsubscribe)).subscribe(translated => {
            this.error = new FormMessageModel("already-refunded", translated, this.translation.currentLanguage, false);
            this.formData.Error = this.error;
          });
        } else if (this.settings.refund.rejectRefundRequestBeforeMinutesHaveElapsed || this.settings.refund.rejectRefundRequestAfterMinutesHaveElapsed) {
          // Let's see if we are too early or too late to submit a refund request
          const then = Helper.getDateFromString(this.formData.Purchase.RequestDateTime, this.formData.Purchase.TimeZone);
          // HACK: We're using UTC for comparison here since that is currently what is used but
          // in theory we could be getting back date in another time zone.  We need client side
          // code to get a date in a desired time zone.  We know the time zone name but not the
          // UTC offset so we can't just +/- hours or minutes.  Perhaps we get a current UTC
          // offset for the user object which we could then use???
          const now = new Date(Helper.getCurrentDateTimeIsoStringInUserTimeZone(0)); // UTC
          const duration = differenceInMinutes(now, then);
          Log.debugMessage(`${duration} minutes elapsed between purchase request ${Helper.formatDateTime(then, "datetime")} (${this.formData.Purchase.TimeZone}) and now ${Helper.formatDateTime(now, "datetime")}.`);
          if (this.settings.refund.rejectRefundRequestBeforeMinutesHaveElapsed && duration < this.settings.refund.rejectRefundRequestBeforeMinutesHaveElapsed) {
            const message = Helper.getFirstDefinedString(this.settings.refund.refundRequestTooSoonErrorMessage, "This purchase is not available for refund yet.  Please try again later.");
            this.translation.getTranslationObservable(message).pipe(takeUntil(this.ngUnsubscribe)).subscribe(translated => {
              this.error = new FormMessageModel("too-early", translated, this.translation.currentLanguage, false);
              this.formData.Error = this.error;
            });
          } else if (this.settings.refund.rejectRefundRequestAfterMinutesHaveElapsed && duration > this.settings.refund.rejectRefundRequestAfterMinutesHaveElapsed) {
            const message = Helper.getFirstDefinedString(this.settings.refund.refundRequestTooLateErrorMessage, "This purchase is no longer available for refund.");
            this.translation.getTranslationObservable(message).pipe(takeUntil(this.ngUnsubscribe)).subscribe(translated => {
              this.error = new FormMessageModel("too-late", translated, this.translation.currentLanguage, false);
              this.formData.Error = this.error;
            });
          }
        }
        // Reason and Comments are left for the user to enter
        this.loadingCount--;
      } else {
        // Let custom form handle display of the error via below error message ... this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.loadingCount--;
        this.formData = new RefundFormModel();
        let message = Helper.getFirstDefinedString(this.settings.refund.paymentLoadErrorMessage, "Error accessing purchase with id {{PaymentId}}: {{Message}}");
        let type = "api";
        if (result.Data.ResultCode === 1101) {
          message = Helper.getFirstDefinedString(this.settings.refund.paymentIdNotFoundMessage, "Purchase with id {{PaymentId}} not found.");
          type = "not-found";
        }
        message = this.translation.getTranslation(message, { PaymentId: this.paymentId, Message: result.Data.Message });
        this.error = new FormMessageModel(type, message, this.translation.currentLanguage, false);
        this.formData.Error = this.error;
      }
    });

  }


  updateFormStatus($event) {
    //console.error("form status", $event);
  }


  submitRefund($event) {

    const model: m5pay.PaymentTransactionRefundAddViewModel = $event.data.Refund;
    // We do not want the refund processed real-time just submit but don't process
    (model as any).Process = false;

    // Check to see if we have an error to show for possible required fields not being populated
    let message = "";
    if (this.settings.refund.refundRequestRequiredReasonErrorMessage && !model.Reason) {
      message = this.settings.refund.refundRequestRequiredReasonErrorMessage;
    } else if (this.settings.refund.refundRequestRequiredCommentErrorMessage && !model.Comments) {
      message = this.settings.refund.refundRequestRequiredCommentErrorMessage;
    }
    if (message) {
      message = this.translation.getTranslation(message);
      this.error = new FormMessageModel("required", message, this.translation.currentLanguage);
      this.formData.Error = this.error;
      return;
    } else {
      this.error = null;
      this.formData.Error = null;
    }

    this.working = true;
    const apiProp: ApiProperties = ApiModulePayment.PaymentTransactionRefund();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    this.apiService.execute(apiCall, model).subscribe((result: IApiResponseWrapperTyped<m5pay.PaymentTransactionEditViewModel>) => {
      this.working = false;
      if (result.Data.Success) {
        // Set success message if we have one
        if (this.settings.refund.refundRequestSubmitSuccessMessage) {
          const message = this.translation.getTranslation(this.settings.refund.refundRequestSubmitSuccessMessage);
          this.formData.Message = new FormMessageModel("api-success", message, this.translation.currentLanguage);
        }
        // This gets passed through to form render component and will trigger save action call for by form
        this.saveCount++;
      } else {
        // Let custom form handle display of the error via below error message ... this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        // This gets passed through to form render component and will post the error on the form.
        message = Helper.getFirstDefinedString(this.settings.refund.refundRequestSubmitErrorMessage, "Error submitting refund request: {{Message}}");
        message = this.translation.getTranslation(message, { Message: result.Data.Message });
        this.error = new FormMessageModel("api", message, this.translation.currentLanguage);
        this.formData.Error = this.error;
      }
    });

  }


}


export class RefundFormModel {
  public Purchase: m5pay.PaymentTransactionEditViewModel = new m5pay.PaymentTransactionEditViewModel();
  public Refund: m5pay.PaymentTransactionRefundAddViewModel = new m5pay.PaymentTransactionRefundAddViewModel();
  // Note that we do NOT get back a m5.ContactListViewModel object but we do have key parts of it available.
  public Contact: any = new m5.ContactListViewModel();
  public Error: FormMessageModel = null;
  public Message: FormMessageModel = null;
  constructor() {
    // This will help suppress a bunch of console error messages in scenario
    // where child objects are not assigned due to load error, etc.
    this.Purchase.Attributes = {};
    this.Refund.Attributes = {};
    this.Contact.Language = "en";
  }
}
