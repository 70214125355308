<ng-progress></ng-progress>
<div class="ib-site-wrapper">
  <div class="ib-site-container hidden">
    <div class="container-fluid">
      <ib-alert></ib-alert>
      <!--<h3>Hello Kiosk!</h3>-->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
