<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label *ngIf="!fullWidth"
         for="{{inputControlId}}"
         class="{{inputLabelClass}}"
         [ngClass]="{ 'pb-0': richTextMode==='optional' }"
         ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)"
               ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    <ng-container *ngIf="richTextMode==='optional'">
      <span *ngIf="label || labelPrefix || labelSuffix"><br /></span>
      <a (click)="richText = !richText"
         [ngbTooltip]="( richText ? 'Switch to Plain Text' : 'Switch to Rich Text' )"
         class="clickable"
         [ngClass]="vertical ? 'float-start' : 'float-end'">
        <i class="text-primary"
           [ngClass]="{'me-1 far': true, 'fa-toggle-on': richText, 'fa-toggle-off': !richText}"></i>
        <i class="text-primary"
           style="font-size: 80%;"
           [ngClass]="{'fas': richText, 'fal': !richText, 'fa-font-case': true}"></i>
      </a>
    </ng-container>
  </label>
  <div class="{{inputWrapperClass}}"
       [hidden]="richText">
    <div class="{{inputGroupClass}}">
      <span *ngIf="prefixText || prefixIcon"
            class="input-group-text"
            ngbTooltip="{{prefixTooltip | translate}}"
            [ngClass]="{'clickable': isPrefixClickable()}"
            (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
      <textarea class="form-control {{inputSize}} {{controlClass}}"
                id="{{inputControlId}}"
                name="{{name}}"
                placeholder="{{( placeholder | translate ) || placeholder}}"
                ngbTooltip="{{tooltip | translate}}"
                pattern="{{pattern}}"
                [rows]="rows"
                [wrap]="wrap"
                [style]="style"
                [maxlength]="maxlength"
                [minlength]="minlength"
                [disabled]="disabled ? 'disabled' : null"
                [readonly]="readonly ? 'readonly' : null"
                [required]="required ? 'required' : null"
                [cdkTextareaAutosize]="autoExpand"
                [cdkAutosizeMinRows]="rows"
                [cdkAutosizeMaxRows]="maxRows"
                (focus)="fireFocus($event, inputControl)"
                (blur)="fireBlur($event, inputControl)"
                (keyup)="fireKeyUp($event, inputControl); autoExpandTextArea($event, textArea);"
                (change)="fireChange($event, inputControl)"
                [(ngModel)]="value"
                [ngModelOptions]="{standalone: standalone}"
                #inputControl="ngModel"
                #textArea>
      </textarea>
      <span *ngIf="suffixText || suffixIcon"
            class="input-group-text"
            ngbTooltip="{{suffixTooltip | translate}}"
            [ngClass]="{'clickable': isSuffixClickable()}"
            (click)="fireSuffixClick($event)">
        {{suffixText | translate}}
        <ib-icon icon="{{suffixIcon}}"></ib-icon>
      </span>
    </div>
    <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
      <small *ngFor="let errorMessage of errorMessages"
             class="form-text input-invalid input-error-message">
        {{errorMessage | translate:inputInformationValues}}
      </small>
    </div>
  </div>
  <div class="{{inputWrapperClass}}"
       *ngIf="richText">
    <!--ngIf because hiding still causes html editor to 'eat' space and enter keys in favor of html, etc.-->
    <ib-html-editor [height]="richComponentHeightPx"
                    [uploadImageAssets]="uploadImageAssets"
                    [systemAssetGroup]="systemAssetGroup"
                    [assetOwnerResourceType]="assetOwnerResourceType"
                    [assetOwnerResourceId]="assetOwnerResourceId"
                    [assetOwnerResourceId2]="assetOwnerResourceId2"
                    [assetSecondaryOwnerResourceType]="assetSecondaryOwnerResourceType"
                    [assetSecondaryOwnerResourceId]="assetSecondaryOwnerResourceId"
                    [assetSecondaryOwnerResourceId2]="assetSecondaryOwnerResourceId2"
                    [(ngModel)]="value"
                    [ngModelOptions]="{standalone: standalone}"
                    (keyup)="fireKeyUp($event, inputControl)"></ib-html-editor>
  </div>
  <div *ngIf="characterCountTemplate"
       class="{{Constants.Layout.fullWidth}}">
    <small class="form-text float-end">
      {{characterCountTemplate | translate:inputInformationValues}}
      <!--<div [translate]="characterCountTemplate" [translateParams]="inputInformationValues"></div>-->
    </small>
  </div>
</div>
